// eslint-disable-next-line import/no-default-export
import { ProductShortProj } from '~/customQueries/projections'

// eslint-disable-next-line import/no-default-export
export default `#graphql
${ProductShortProj}
  query productsList($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations {
        attribute_code
        label
        options {
          label
          value
          count
        }
      }
      items {
        ...ProductShortProj
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`
