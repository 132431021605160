import Vue from 'vue'

const className: string = 'ssr-state'

// eslint-disable-next-line import/no-default-export
export default function (): void {
  Vue.directive('ssr-class', {
    bind: (el: HTMLElement) => {
      updateClass(el)
    }
  })
}

function updateClass (el: HTMLElement): void {
  if (process.server) {
    el.classList.add(className)
  } else {
    el.classList.remove(className)
  }
}
