import { TranslateResult } from 'vue-i18n'
import { Product } from '~/modules/catalog/product/types'
import { Maybe, Scalars, ProductDiscount, Money } from '~/modules/GraphQL/types'

export enum PICKUP_MACHINE_VALUES {
  YES = 45,
  NO = 46
}

export interface Breadcrumb {
  text: string
  link: string
}

export interface Price {
  regular: number | null
  special?: number | null
  maximum?: number | null
  final?: number | null
}

export interface MediaGalleryItem {
  small: string
  normal: string
  big: string
  position: number | undefined
}

export interface ImgPath {
  url: string
}

export interface ProductGallery {
  mobile: ImgPath
  desktop: ImgPath
  big: ImgPath
  alt: string
  position: number | undefined
}

export interface CategoryAggregationOption {
  product_count: number
  id: number
  name: string
}

export interface CategoryAggregation {
  children: CategoryAggregationOption[]
}

export interface Transport {
  iconName: string
  text: TranslateResult | string
}

export interface SpecificFilterAttributes {
  codes: string[]
  hasOrderPriority: boolean
}

/**
 * The data structure for filter which should display on specific pages (categories, etc.)
 * Field: url_path or identifier
 * Value: attribute code of filter
 */

export interface SpecificFilters {
  [key: string]: SpecificFilterAttributes
}

export enum GiftCardTypeEnum {
  Email = 'E-gift card',
  Paper = 'Paper gift card'
}

export interface GiftCardType {
  value: GiftCardTypeEnum
}

export interface GiftCardModel {
  giftcard_from: string
  giftcard_to: string
  giftcard_message: string
  giftcard_type: GiftCardType
  giftcard_amount: number
  giftcard_email: string
}

export interface SpecialPriceTier {
  quantity?: Maybe<Scalars['Int']>
  discount?: Maybe<ProductDiscount>
  final_price?: Money
}

export interface AatriumProduct extends Product {
  available_price_tiers?: SpecialPriceTier[]
  width?: string
  height?: string
}

export enum ProductStoreSourceCode {
  PEA = 'pea',
  RAIN = 'rain',
  MUUGISAAL = 'muugisaal',
  VAIKE = 'vaike'
}

export interface ProductAvailabilityItem {
  colorClass: string
  text: string
  show: boolean
}
