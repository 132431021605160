import { defineStore } from 'pinia'
import { LAST_VIEWED_MAX_NUMBER, LAST_VIEWED_PRODUCTS_KEY, LastViewedProductsStateInterface } from '../variables'
import { Product } from '~/modules/catalog/product/types'
import { getRecentProductListCommand } from '~/modules/last-viewed-products/commands/getRecentProductListCommand/getRecentProductListCommand'

export const useLastViewedProductsStore = defineStore('lastViewedProducts', {
  state: (): LastViewedProductsStateInterface => ({
    products: []
  }),
  actions: {
    _updateLc (): void {
      localStorage.setItem(LAST_VIEWED_PRODUCTS_KEY, JSON.stringify(this.products.map(p => p.sku)))
    },
    async load (): Promise<void> {
      const { execute } = getRecentProductListCommand()

      const skusLc: string = await localStorage.getItem(LAST_VIEWED_PRODUCTS_KEY) || ''
      const arrSkus: string[] = skusLc ? JSON.parse(skusLc) : []

      if (arrSkus.length) {
        const fetchedProducts = await execute({ filter: { sku: { in: arrSkus } } })
        fetchedProducts.items.sort((x, y) => arrSkus.indexOf(x.sku) - arrSkus.indexOf(y.sku))
        this.products = fetchedProducts?.items as Product[] || []
      }
    },
    add (product: Product): void {
      if (!product?.sku) return

      this.products = this.products.filter(p => p.sku !== product.sku)
      this.products = [product, ...this.products]
      this.products = this.products.slice(0, LAST_VIEWED_MAX_NUMBER)
      this._updateLc()
    }
  }
})
