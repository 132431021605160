import { computed, ComputedRef } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { CartItemInterface, Maybe, useCart } from '~/composables'
import { UseAssemblyProducts } from '~/composables/useAssemblyProductsList/interfaces/UseAssemblyProducts'
import { useAssemblyProductsStore } from '~/composables/useAssemblyProductsList/store/useAssemblyProductsStore'

export function useAssemblyProductsList (): UseAssemblyProducts {
  const { cart, removeItem } = useCart()
  const assemblyStore = useAssemblyProductsStore()
  const { load } = assemblyStore
  const { products, loading, error } = storeToRefs(assemblyStore)

  const addedProductsInCart: ComputedRef<CartItemInterface[]> = computed((): CartItemInterface[] => {
    return cart.value?.items?.filter((i: Maybe<CartItemInterface>) => skusOfProducts.value.includes(i.product.sku)) || []
  })

  const removeAllAssemblyProducts = async (): Promise<void> => {
    for (const product of addedProductsInCart.value) {
      await removeItem({ product })
    }
  }

  const skusOfProducts = computed(() => products.value?.map(product => product.sku))

  return {
    addedProductsInCart,
    products,
    loading,
    error,
    load,
    removeAllAssemblyProducts
  }
}
