export const getAssemblyProductsQuery: string = `
mutation {
  getAssemblyProducts {
    items {
      uid
      sku
      name
      price_range {
        maximum_price {
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
      }
    }
  }
}
`
