import { Plugin } from '@nuxt/types'

/**
 * Declaration of the required 'stamp' attribute of the 'Cookiebot' object.
 */
declare global {
  interface Window {
    Cookiebot: {
      consent: {
        stamp: string
      }
    }
  }
}

/**
 * This plugin contains functionality to reload the page after the customer has accepted/declined/modified the 'cookie' agreements.
 */

// eslint-disable-next-line func-style
const cookiebotPlugin: Plugin = ({ app }) => {
  if (!process.client) {
    return
  }

  /**
   * An array that contains the latest event data or 'stamp' id as strings.
   */
  const cookiebotConcentStorage: string[] = []

  window.addEventListener('load', () => {
    cookiebotConcentStorage.push(window.Cookiebot?.consent?.stamp)
  })

  /**
   * The native 'CookiebotOnAccept' event is triggered when a user accepts a 'cookie' agreement.
   */
  window.addEventListener('CookiebotOnAccept', consentListenerCallback)
  /**
   * The native 'CookiebotOnDecline' event is triggered when a user decline a 'cookie' agreement.
   */
  window.addEventListener('CookiebotOnDecline', consentListenerCallback)

  function consentListenerCallback (event: any): void {
    cookiebotConcentStorage.push(event.type)

    /**
     * A reload is required if the 'cookie' agreement has been changed.
     */
    if (cookiebotConcentStorage[0] === '0' || cookiebotConcentStorage.length >= 3) {
      return location.reload()
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default cookiebotPlugin
