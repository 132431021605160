import { AvailabilityInStoresProj } from './AvailabilityInStores.proj'

export const ProductAvailabilityInStoresProj = `#graphql
${AvailabilityInStoresProj}
fragment ProductAvailabilityInStoresProj on ProductInterface {
  availability_in_stores {
    ...AvailabilityInStoresProj
  }
}
`
