import { buildUseStrapiComposable, UseZaprooStrapi, ZaprooStrapiConfig } from '@zaprooecommerce/strapi'
import { AatriumStrapiSectionTypes } from '~/modules/strapi/interfaces/AatriumStrapiSectionTypes'
import { prepareNoticeWidget } from '~/modules/strapi/prepareContentSections/prepareNoticeWidget'
import { prepareProductsWidget } from '~/modules/strapi/prepareContentSections/prepareProductsWidget'
import { prepareSingleBannerWidget } from '~/modules/strapi/prepareContentSections/prepareSingleBannerWidget'
import { AatriumStrapiSection, StrapiSectionProducts } from '~/modules/strapi/types'

const AATRIUM_STRAPI_CONFIG: ZaprooStrapiConfig<typeof AatriumStrapiSectionTypes, AatriumStrapiSection> = {
  sectionTypes: AatriumStrapiSectionTypes,
  sectionTypesConfig: {
    CategoryProducts: {
      factory: prepareProductsWidget,
      component: async () => await import('~/components/organisms/CategoryProducts.vue'),
      propsMapping (section: StrapiSectionProducts): Record<string, any> {
        return { products: section.fetchedProducts, title: section.Title, seeMore: section?.seeMore }
      }
    },
    SingleBanner: {
      factory: prepareSingleBannerWidget,
      component: async () => await import('~/components/organisms/Banner/SingleBanner.vue')
    },
    Newsletter: {
      component: async () => await import('~/components/organisms/Newsletter.vue')
    },
    Notice: {
      factory: prepareNoticeWidget,
      component: async () => await import('~/components/organisms/Notice.vue')
    },
    ContactColumns: {
      component: async () => await import('~/modules/strapi/components/ContactColumns.vue'),
      propsMapping (section): Record<string, any> {
        return { section }
      }
    },
    ContactDepartments: {
      component: async () => await import('~/modules/strapi/components/ContactDepartments.vue'),
      propsMapping (section): Record<string, any> {
        return { section }
      }
    }
  }
}

export const useStrapi: () => UseZaprooStrapi<AatriumStrapiSection> =
  buildUseStrapiComposable<typeof AatriumStrapiSectionTypes, AatriumStrapiSection>(AATRIUM_STRAPI_CONFIG)
