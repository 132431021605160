
import { defineComponent, ref, watch } from '@nuxtjs/composition-api'
import { useUiState } from '~/composables'

import { clickOutside } from '~/components/directives/click-outside/click-outside-directive'
import HeaderLogo from '~/components/HeaderLogo.vue'
import AccountButton from '~/components/Header/Buttons/AccountButton.vue'
import CartButton from '~/components/Header/Buttons/CartButton.vue'
import WishlistButton from '~/components/Header/Buttons/WishlistButton.vue'
import SearchButton from '~/components/Header/Buttons/SearchButton.vue'
import SearchBar from '~/components/Header/SearchBar/SearchBar.vue'

export default defineComponent({
  components: {
    AccountButton,
    CartButton,
    WishlistButton,
    HeaderLogo,
    SearchButton,
    SearchBar,
    TopBar: () => import('~/components/TopBar.vue'),
    Overlay: () => import('~/components/Overlay.vue'),
    SearchResults: () => import('~/components/Header/SearchBar/SearchResults.vue')
  },
  directives: {
    clickOutside
  },
  setup () {
    const { isSearchOpen, toggleSearch } = useUiState()
    const term = ref('')
    const searchResultsRef = ref(null)

    function closeSearchMenu () :void {
      if (isSearchOpen.value) {
        toggleSearch()
      }
    }

    function openSearchMenu ():void {
      if (!isSearchOpen.value) {
        toggleSearch()
      }
    }

    function onSearchBarSubmit (): void {
      searchResultsRef.value?.goToSearch()
    }

    watch(isSearchOpen, (isOpen) => {
      if (!isOpen) {
        term.value = ''
      }
    })

    return { isSearchOpen, searchResultsRef, toggleSearch, term, closeSearchMenu, openSearchMenu, onSearchBarSubmit }
  }
})
