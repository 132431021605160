
import { computed, defineComponent, useRoute, onMounted } from '@nuxtjs/composition-api'
import LazyHydrate from 'vue-lazy-hydration'
import { useUiState } from '~/composables'
import { AuthSidebarMode } from '~/composables/useUiState/enums/AuthSidebarMode'
import { EstAuthMethod } from '~/composables/useEstAuth/types'
import { useEstAuth } from '~/composables/useEstAuth'

import AppHeader from '~/components/AppHeader.vue'
import LayoutSidebarsPortal from '~/components/LayoutSidebarsPortal.vue'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    AppHeader,
    LazyHydrate,
    MegaMenu: () => import('~/modules/mega-menu/components/MegaMenu.vue'),
    AppMinimalHeader: () => import(/* webpackPrefetch: true */ '~/components/AppMinimalHeader.vue'),
    AppMinimalFooter: () => import(/* webpackPrefetch: true */ '~/components/AppMinimalFooter.vue'),
    LayoutSidebarsPortal,
    BankLogos: () => import(/* webpackPrefetch: true */ '~/components/Footer/BankLogos.vue'),
    ManagedNewsletterModal: () => import('~/modules/managed-newsletter/components/ManagedNewsletterModal.vue'),
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    Notifications: () => import(/* webpackPrefetch: true */ '~/components/Notifications.vue')
  },
  setup () {
    const route = useRoute()
    const { setMethod } = useEstAuth()
    const { toggleAuthSidebar, setAuthSidebarMode, isErrorPageView } = useUiState()

    const isMegaMenuDisable = computed<boolean>(() => (
      route.value?.path.includes('thank-you') || route.value?.path.includes('my-account')
    ))
    const isNewPassSidebarOpenByQuery = computed<boolean>(() => route.value.query?.modal === 'reset-password')
    const isIdCardSidebarOpenByQuery = computed<boolean>(() => {
      const { id_card: isIdCard, error: hasError, token } = route.value.query
      return !!isIdCard && !!(hasError || token)
    })
    const isRequestPassSidebarOpenByQuery = computed<boolean>(() => route.value.query?.modal === 'request-reset-password')

    onMounted(() => {
      if (isIdCardSidebarOpenByQuery.value) {
        const { mode } = route.value.query
        const currentSidebarMode = (mode as AuthSidebarMode) === AuthSidebarMode.register
          ? AuthSidebarMode.preRegister
          : AuthSidebarMode.login
        setAuthSidebarMode(currentSidebarMode)
        setMethod(EstAuthMethod.CardId)
        toggleAuthSidebar()
      }

      if (isNewPassSidebarOpenByQuery.value) {
        setAuthSidebarMode(AuthSidebarMode.newPassword)
        toggleAuthSidebar()
      }

      if (isRequestPassSidebarOpenByQuery.value) {
        setAuthSidebarMode(AuthSidebarMode.resetPassword)
        toggleAuthSidebar()
      }
    })

    return {
      isErrorPageView,
      isMegaMenuDisable,
      route
    }
  }
})
