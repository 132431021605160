export const createCompany: (countryCode: string) => string = (countryCode: string = 'EE') => `
  mutation createCompany(
    $company_name: String!
    $company_email: String!
    $company_admin: CompanyAdminInput!
    $legal_name: String
    $street: [String]!
    $city: String!
    $region: CustomerAddressRegionInput!
    $postcode: String!
    $telephone: String!
    $vat_tax_id: String!
    $reseller_id: String!
  ) {
      createCompany(input: {
        company_name: $company_name
        company_email: $company_email
        company_admin: $company_admin
        legal_name: $legal_name
        legal_address: {
          street: $street
          city: $city
          region: $region
          postcode: $postcode
          telephone: $telephone
          country_id: ${countryCode}
        }
        vat_tax_id: $vat_tax_id
        reseller_id: $reseller_id
      }) {
          company {
              id
              name
              company_admin {
                  email
                  firstname
                  lastname
              }
              legal_address {
                  street
                  city
                  region {
                      region_code
                      region_id
                  }
                  postcode
                  telephone
              }
          }
          token
      }
  }
`;
