
import { defineComponent, ref, watch, onMounted, onUnmounted } from '@nuxtjs/composition-api'
import { useUiState } from '~/composables'
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive'

import BaseButton from '~/components/BaseButton.vue'
import SearchBar from '~/components/Header/SearchBar/SearchBar.vue'

export default defineComponent({
  name: 'NotFound',
  directives: { clickOutside },
  components: {
    BaseButton,
    SearchBar,
    SearchResults: () => import('~/components/Header/SearchBar/SearchResults.vue')
  },
  setup () {
    const { isSearchOpen, toggleSearch, changeToErrorPageView, changeToDefaultPageView } = useUiState()
    const term = ref('')
    const searchResultsRef = ref(null)

    function closeSearchMenu () {
      if (isSearchOpen.value) {
        toggleSearch()
      }
    }

    function openSearchMenu () {
      if (!isSearchOpen.value) {
        toggleSearch()
      }
    }

    function onSearchBarSubmit (): void {
      searchResultsRef.value?.goToSearch()
    }

    onMounted(() => {
      changeToErrorPageView()
    })

    onUnmounted(() => {
      changeToDefaultPageView()
    })

    watch(isSearchOpen, (isOpen) => {
      if (!isOpen) {
        term.value = ''
      }
    })

    return {
      term,
      searchResultsRef,
      isSearchOpen,
      closeSearchMenu,
      openSearchMenu,
      onSearchBarSubmit
    }
  },
  head () {
    return {
      title: '404 - page'
    }
  }
})
