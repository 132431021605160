import { StrapiSection, WrappedPrepareContentPageSectionFnFactory } from '@zaprooecommerce/strapi'
import { StrapiSectionSingleBanner } from '~/modules/strapi/types'

export function prepareSingleBannerWidget (): WrappedPrepareContentPageSectionFnFactory<StrapiSectionSingleBanner> {
  return (section: StrapiSection, preparedSection: StrapiSectionSingleBanner) => {
    preparedSection.banners = section.slider.map((slider) => {
      return {
        ...slider
      }
    })
    return preparedSection
  }
}
