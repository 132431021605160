import { Plugin } from '@nuxt/types'

// eslint-disable-next-line func-style
const asklyPlugin: Plugin = ({ app, $config }) => {
  if (!process.client) {
    return
  }

  function setAsklyScript (): void {
    const { asklyConfig } = $config

    setTimeout(() => {
      if (asklyConfig?.asklyKey) {
        const script = document.createElement('script')
        script.setAttribute('tw-client-key', asklyConfig.asklyKey)
        script.setAttribute('defer', 'true')
        script.setAttribute('async', 'true')
        script.src = 'https://chat.askly.me/cw/chat/latest.js'
        document.head.append(script)
      }
    }, 8000)

    window.removeEventListener('load', setAsklyScript)
  }

  if (document.readyState === 'complete') {
    setAsklyScript()
  } else {
    window.addEventListener('load', setAsklyScript)
  }
}

// eslint-disable-next-line import/no-default-export
export default asklyPlugin
