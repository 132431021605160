import { TranslateResult } from 'vue-i18n'
import { ComputedRef } from '@nuxtjs/composition-api'
import { AuthSidebarMode } from '~/composables/useUiState/enums/AuthSidebarMode'
import { EstAuthResultInterface } from '~/composables/useEstAuth/interfaces/EstAuthResultInterface'
import { EstAuthErrorInterface } from '~/composables/useEstAuth/interfaces/EstAuthErrorInterface'

export enum EstAuthMethod {
  MobileId = 'mobile_id',
  SmartId = 'smart_id',
  CardId = 'card_id',
  Email = 'email'
}

export enum EstAuthCustomerType {
  Personal = 'personal',
  Business = 'business'
}

export enum EstAuthState {
  CodeReceived,
  NotRegistered,
  Registered,
  Exists,
  Failed,
  Authenticated
}

export interface EstAuthMobileIdCodeInput {
  customerType?: EstAuthCustomerType
  phone: String
  identityNumber: String
}

export interface EstAuthSmartIdCodeInput {
  customerType?: EstAuthCustomerType
  identityNumber: String
  countryCode: String
}

export interface EstAuthCardIdCodeInput {
  customerType?: EstAuthCustomerType
}

export interface EstAuthEmailInput {
  customerType?: EstAuthCustomerType
  email?: string
  password: string
  recaptchaToken?: string
}

export interface EstAuthSetNewPasswordInput {
  token: string
  email: string
  password: string
  recaptchaToken?: string
}

export type EstAuthCodeInput = EstAuthMobileIdCodeInput | EstAuthSmartIdCodeInput | EstAuthCardIdCodeInput | EstAuthEmailInput

export interface IEstAuthMethod {
  generateCode?: Function
  getToken?(userData: EstAuthCodeInput, mode: AuthSidebarMode): Promise<string>
}

export interface EstAuthGenerateCustomerTokenInput {
  identity?: string
  identityNumber: string
  code: string
  customerType?: EstAuthCustomerType
  email?: string
}

export interface EstAuthMethods {
  text: TranslateResult | string
  value: EstAuthMethod
}

export interface UseEstAuth {
  setMethod(value: EstAuthMethod): void
  generateCode(input: EstAuthCodeInput, mode?: AuthSidebarMode): Promise<EstAuthState>
  generateCustomerToken(params: EstAuthGenerateCustomerTokenInput, isMobile: boolean): Promise<EstAuthState>
  loginViaEmailPassword(email: string, password: string, recaptchaToken?: string): Promise<EstAuthState>
  login(token: string, mergeCarts?: boolean): Promise<EstAuthState>
  register(customerType: EstAuthCustomerType, input: any): Promise<EstAuthState>
  resetCode(): void
  resetPassword(email: string, recaptchaToken?: string): Promise<boolean>
  setNewPassword(params: EstAuthSetNewPasswordInput): Promise<boolean>
  methods: ComputedRef<EstAuthMethods[]>
  isSelectedSimpleAuthMethod: ComputedRef<boolean>
  method: ComputedRef<EstAuthMethod>
  token: ComputedRef<string | null>
  loading: ComputedRef<boolean>
  result: ComputedRef<EstAuthResultInterface>
  error: ComputedRef<EstAuthErrorInterface>
}
