import { useContext } from '@nuxtjs/composition-api'
import { StrapiSection, WrappedPrepareContentPageSectionFnFactory } from '@zaprooecommerce/strapi'
import { StrapiSectionNotice } from '~/modules/strapi/types'

export function prepareNoticeWidget (): WrappedPrepareContentPageSectionFnFactory<StrapiSectionNotice> {
  const { $config } = useContext()
  const strapiMediaUrl: string = $config.strapiMediaUrl

  return (section: StrapiSection, preparedSection: StrapiSectionNotice) => {
    preparedSection.image.url = `${strapiMediaUrl}${preparedSection.image.url}`

    return preparedSection
  }
}
