import { CartDiscount, CartGetters as CartGettersBase, Coupon, CustomizableOptionItem } from './types'
import type { Totals } from '~/composables/types'
import type { Price } from '~/modules/catalog/types'
import type { Product, ProductAttribute } from '~/modules/catalog/product/types'
import { PaymentMethodInterface } from '~/modules/checkout/types'
import {
  Cart,
  CartItemInterface,
  ConfigurableCartItem,
  Discount,
  ProductInterface,
  SelectedShippingMethod, SimpleCartItem, UrlRewrite
} from '~/modules/GraphQL/types'
import { getName, getProductThumbnailImage, getSlug as getSlugGetter } from '~/modules/catalog/product/getters/productGetters'

export function getItems (cart: Cart): CartItemInterface[] {
  if (!cart?.items) {
    return []
  }

  return cart.items
}

export function getItemName (product: CartItemInterface): string {
  return getName(product.product as Product)
}
export function getSlug (product: CartItemInterface | { product: {sku: string, url_key: string, url_rewrites?: UrlRewrite[]} }): string {
  return getSlugGetter(product.product as Product)
}

export function getItemImage (product: CartItemInterface): string {
  return getProductThumbnailImage(product.product as Product)
}

export function getItemPrice (product: CartItemInterface, includedTax: boolean = false): Price {
  if (!product?.prices) {
    return {
      regular: 0,
      special: 0
    }
  }
  if (product.prices) {
    return {
      regular: includedTax ? (product.prices.row_total_including_tax.value || 0) : (product.prices.row_total.value || 0),
      special: product.prices.total_item_discount.value || 0
    }
  }
  const regularPrice = product.product?.price_range?.minimum_price?.regular_price?.value
  const specialPrice = product.product?.price_range?.minimum_price?.final_price?.value

  return {
    regular: regularPrice || 0,
    special: specialPrice || 0,
    // @ts-expect-error
    credit: Math.round(specialPrice / 10),
    discountPercentage: 100 - Math.round((specialPrice / regularPrice) * 100),
    total: product.prices?.row_total?.value
  }
}

export function productHasSpecialPrice (product: CartItemInterface): boolean {
  return getItemPrice(product).regular < getItemPrice(product).special
}

export function getItemQty (product: CartItemInterface): number {
  return product.quantity
}

export function getItemAttributes (
  { product }: CartItemInterface & { product: Product },
  _filterByAttributeName?: string[]
): Record<string, ProductAttribute | string> {
  const attributes: Record<string, ProductAttribute | string> = {}

  if (!product?.configurable_options) {
    return attributes
  }

  const configurableOptions = product.configurable_options

  // eslint-disable-next-line no-restricted-syntax
  for (const option of configurableOptions) {
    attributes[option.attribute_code] = {
      name: option.attribute_code,
      label: option.label,
      value: option.values.map((value) => {
        const obj = {}
        obj[value.value_index] = value.label
        return obj
      })
    }
  }
  return attributes
}

export function getItemSku (product: CartItemInterface): string {
  return product?.product?.sku || ''
}

function calculateDiscounts (discounts: Discount[]): number {
  return discounts.reduce((a, b) => Number.parseFloat(`${a}`) + Number.parseFloat(`${b.amount.value}`), 0)
}

export function getTotals (cart: Cart): Totals {
  if (!cart?.prices) {
    return {
      total: 0,
      subtotal: 0
    }
  }

  const subtotal = cart.prices.subtotal_including_tax.value
  return {
    total: cart.prices.grand_total.value,
    subtotal: cart.prices.subtotal_including_tax.value,
    special: (cart.prices.discounts) ? subtotal - calculateDiscounts(cart.prices.discounts) : subtotal
  }
}

export function getShippingPrice (cart: Cart): number {
  if (!cart.shipping_addresses) {
    return 0
  }

  return cart.shipping_addresses
    .reduce((
      acc,
      shippingAddress
    ) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { selected_shipping_method } = shippingAddress

      if (selected_shipping_method) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return acc + selected_shipping_method.amount.value
      }

      return acc
    }, 0)
}

export function getTotalItems (cart: Cart): number {
  if (!cart) {
    return 0
  }
  return cart.total_quantity
}

export function getConfiguredVariant (product: ConfigurableCartItem): ProductInterface | null {
  return product?.configured_variant || null
}

export function getCoupons (cart: Cart): Coupon[] {
  return (Array.isArray(cart?.applied_coupons)
    ? cart.applied_coupons.map(c => ({
      id: c.code,
      name: c.code,
      value: 0,
      code: c.code
    }))
    : [])
}

export function getDiscounts (cart: Cart): CartDiscount[] {
  return (Array.isArray(cart?.prices?.discounts)
    ? cart.prices.discounts.map(d => ({
      id: d.label,
      name: d.label,
      description: '',
      value: d.amount.value,
      code: d.label
    }))
    : [])
}

export function getDiscountAmount (cart: Cart): number {
  return calculateDiscounts(cart?.prices?.discounts ?? [])
}

export function getAppliedCoupon (cart: Cart): Coupon | null {
  return (Array.isArray(cart?.applied_coupons) && cart?.applied_coupons.length > 0
    ? { id: cart.applied_coupons[0].code, name: cart.applied_coupons[0].code, value: 0, code: cart.applied_coupons[0].code }
    : null)
}

export function getSelectedShippingMethod (cart: Cart): SelectedShippingMethod | null {
  return (cart?.shipping_addresses?.length > 0
    ? cart?.shipping_addresses[0]?.selected_shipping_method
    : null)
}

export function getAvailablePaymentMethods (cart: Cart): PaymentMethodInterface[] {
  return cart?.available_payment_methods.map(p => ({
    label: p.title,
    value: p.code
  }))
}

export function getStockStatus (product: CartItemInterface): string {
  return product.product.stock_status
}

export function getCustomizableOptions (product: SimpleCartItem): CustomizableOptionItem[] {
  if (!product.customizable_options) return []

  return product.customizable_options.map((option) => {
    return {
      uid: option.customizable_option_uid,
      label: option.label,
      value: option.values?.length ? option.values.map(value => value.label).join(',') : ''
    }
  })
}

export function getGiftCardsAmount (cart: Cart): number {
  return cart?.prices?.giftcards_amount?.value || 0
}

export interface CartGetters extends CartGettersBase<Cart, CartItemInterface> {
  getAppliedCoupon(cart: Cart): Coupon | null

  getAvailablePaymentMethods(cart: Cart): PaymentMethodInterface[]

  getSelectedShippingMethod(cart: Cart): SelectedShippingMethod | null

  productHasSpecialPrice(product: CartItemInterface): boolean

  getStockStatus(product: CartItemInterface): string

  getConfiguredVariant(product: ConfigurableCartItem): ProductInterface | null

  getCustomizableOptions(product: SimpleCartItem): CustomizableOptionItem[]
}

const cartGetters: CartGetters = {
  getAppliedCoupon,
  getAvailablePaymentMethods,
  getCoupons,
  getDiscounts,
  getItemAttributes,
  getItemImage,
  getItemName,
  getSlug,
  getItemPrice,
  getItemQty,
  getItems,
  getItemSku,
  getSelectedShippingMethod,
  getShippingPrice,
  getTotalItems,
  getTotals,
  getDiscountAmount,
  productHasSpecialPrice,
  getStockStatus,
  getConfiguredVariant,
  getCustomizableOptions,
  getGiftCardsAmount
}

// eslint-disable-next-line import/no-default-export
export default cartGetters
