export const generateCustomerToken = `
  mutation generateCustomerToken ($method: String!, $data: [estAuthDataInput]!) {
    estAuthGenerateCustomerToken (
      method: $method
      data: $data
    ) {
      token
      data {
        key
        value
      }
    }
  }
`;
