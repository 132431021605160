<template>
  <header id="minimal-header">
    <div class="container no-padding">
      <div class="minimal-header">
        <header-logo class="minimal-header__logo" />
        <account-button v-if="!isErrorPageView" />
      </div>
    </div>
  </header>
</template>

<script>
import { useUiState } from '~/composables'

import HeaderLogo from '~/components/HeaderLogo'
import AccountButton from '~/components/Header/Buttons/AccountButton'

export default {
  name: 'AppMinimalHeader',
  components: {
    HeaderLogo,
    AccountButton
  },
  setup () {
    const { isErrorPageView } = useUiState()

    return {
      isErrorPageView
    }
  }
}
</script>

<style lang="scss" scoped>
#minimal-header {
  border-bottom: 1px solid var(--c-light);
}
.minimal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  --button-size: 40px;
  --button-border-radius: 50%;
  --button-background: var(--c-light);

  padding: 12px 0;

  @include for-desktop {
    padding: 24px 0;
  }

  @include for-mobile-up {
    --button-size: 48px;
  }

  &__logo {
    flex-shrink: 0;
  }
}
</style>
