export const customer: string = `
  query customer($currentPage: Int = 1, $filter: CustomerOrdersFilterInput = {}, $pageSize: Int = 10, $sortType: String, $sortDirection: String){
    customer {
      orders(currentPage: $currentPage, filter: $filter, pageSize: $pageSize, sortType: $sortType, sortDirection: $sortDirection) {
        items {
          number
          id
          order_date
          grand_total
          total {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            base_grand_total {
              currency
              value
            }
            shipping_handling {
              amount_excluding_tax {
                currency
                value
              }
              amount_including_tax {
                currency
                value
              }
              discounts {
                amount {
                  currency
                  value
                }
              }
              taxes {
                amount{
                  currency
                  value
                }
                rate
                title
              }
              total_amount {
                currency
                value
              }
            }
            subtotal {
              currency
              value
            }
            taxes {
              amount {
                currency
                value
              }
              rate
              title
            }
            total_shipping {
              currency
              value
            }
            total_tax {
              currency
              value
            }
          }
          status
          items {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            id
            product_name
            product_sale_price {
              currency
              value
            }
            product_sku
            product_type
            product_url_key
            product_image_url
            status
            quantity_ordered
          }
          shipping_method
        }
        page_info {
            current_page
            total_pages
            page_size
        }
        total_count
      }
    }
  }
`
