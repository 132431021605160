import { Plugin } from '@nuxt/types'
import { onGlobalSetup } from '@nuxtjs/composition-api'
import StoreConfigGql from '~/plugins/query/StoreConfig.gql'
import type { StoreConfig } from '~/modules/GraphQL/types'
import { useConfigStore } from '~/stores/config'

// eslint-disable-next-line func-style
const storeConfigPlugin: Plugin = (ctx) => {
  onGlobalSetup(async () => {
    const app = ctx.app
    const configStore = useConfigStore(ctx.$pinia)
    /**
     * We load the store configuration once in the ssr stage, then it goes into the store and in the hydration stage to the client.
     * Remove the line below if you want the configuration to be always updated on the client
     */
    if (!process.server) {
      return
    }

    const { data }: { data: { storeConfig?: StoreConfig } } = await app.$vsf.$magento.api.customQuery({ query: StoreConfigGql })
    configStore.storeConfig = data?.storeConfig ?? {}
  })
}

// eslint-disable-next-line import/no-default-export
export default storeConfigPlugin
