
export const ProductPriceProj = `#graphql
fragment ProductPriceProj on ProductInterface {
  price_range {
    maximum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
    minimum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
  }
  available_price_tiers {
    discount {
      amount_off
      percent_off
    }
    final_price {
      currency
      value
    }
    quantity
  }
}
`
