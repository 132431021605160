export const generateCustomerTokenAsync: string = `
  mutation generateCustomerTokenAsync ($method: String!, $data: [estAuthDataInput]!) {
    estAuthGenerateCustomerTokenAsync (
      method: $method
      data: $data
    ) {
      request_id
      request_secret
    }
  }
`;
