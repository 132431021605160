// eslint-disable-next-line import/no-default-export
import { Middleware } from '@nuxt/types'

const redirectToPersonalDetails: Middleware = async ({ route, redirect }): Promise<void> => {
  if (route.path === '/my-account') {
    await redirect('/my-account/personal-data')
  }
}

// eslint-disable-next-line import/no-default-export
export default redirectToPersonalDetails
