import { KlevuConfig } from '@klevu/core'
import { Plugin } from '@nuxt/types'
import { onGlobalSetup, useContext } from '@nuxtjs/composition-api'
import { CustomKlevuConfig } from '../types/CustomKlevuConfig'
import { useKlevuSettingsStore } from '~/modules/klevu-search/store/useKlevuSettingsStore'
import { useI18n } from '~/helpers/hooks/usei18n'

// eslint-disable-next-line func-style
const klevuPlugin: Plugin = ({ app }) => {
  onGlobalSetup(async () => {
    const { locale } = useI18n()
    const { $config: { storeURL } } = useContext()
    const { loadRootSettings } = useKlevuSettingsStore()
    const url = typeof storeURL === 'string' ? storeURL || '' : ''

    KlevuConfig.init({
      url: `${url}/klevu-api/${locale}/search`,
      apiKey: '---protected---'
    });
    (KlevuConfig.default as CustomKlevuConfig).eventsApiV1Url = `${url}/klevu-api/${locale}/analytics/`
    await loadRootSettings(locale, false)
  })
}

// eslint-disable-next-line import/no-default-export
export default klevuPlugin
