
import { defineComponent, ref } from '@nuxtjs/composition-api'
import SearchButton from '~/components/Header/Buttons/SearchButton.vue'
import { useUiState } from '~/composables'

export default defineComponent({
  name: 'SearchBar',
  components: {
    SearchButton
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search for products or brands...'
    }
  },
  emits: ['click:open', 'input:focus', 'click:close', 'input', 'submit', 'input:blur'],
  setup (_, { emit }) {
    const { isSearchOpen } = useUiState()
    const searchInput = ref<HTMLInputElement>(null)

    function onInputKeyupEnter (): void {
      emit('submit')
      searchInput.value?.blur()
    }

    return { isSearchOpen, searchInput, onInputKeyupEnter }
  }
})

