import { defineStore } from 'pinia'

interface LoadingState {
  loadingCart: boolean
  loadingShipping: boolean
  loadingShippingProvider: boolean
  loadingUser: boolean
  loadingGuestUser: boolean
}

export const useLoadingStore = defineStore('loadingStore', {
  state: (): LoadingState => ({
    loadingCart: false,
    loadingShipping: false,
    loadingShippingProvider: false,
    loadingUser: false,
    loadingGuestUser: false
  })
})
