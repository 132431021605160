<template>
  <base-button class="header-action" v-on="$listeners">
    <slot />
    <div v-if="$slots.badge" class="header-action__badge">
      <slot name="badge" />
    </div>
  </base-button>
</template>

<script>
import BaseButton from '~/components/BaseButton'

export default {
  name: 'HeaderButton',
  components: {
    BaseButton
  }
}
</script>

<style lang="scss">
.header-action {

  &.base-button {
    --button-position: relative;
  }

  &__badge {
    position: absolute;
    top: 0;
    right: 0;

    width: 20px;
    height: 20px;

    line-height: 20px;
    font-weight: var(--font-weight--bold);
    font-size: 10px;

    background: var(--c-black);
    color: var(--c-white);
    border-radius: 50%;
  }
}
</style>
