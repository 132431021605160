export const addWishlistItemsToCartMutation: string = `
  mutation addWishlistItemsToCart($wishlistId: ID! $wishlistItemIds: [ID!]) {
    addWishlistItemsToCart(wishlistId: $wishlistId  wishlistItemIds: $wishlistItemIds) {
      status
      add_wishlist_items_to_cart_user_errors {
        code
        message
      }
      wishlist {
        id
        items_v2 {
          items {
            id
            product {
              uid
              sku
              name
            }
          }
        }
      }
    }
  }
`
