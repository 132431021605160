import { AatriumPaymentGroupMetaItem, AatriumPaymentGroups } from '~/modules/checkout/aatrium/types'

export const LAST_PLACED_ORDER_LC_KEY: string = 'lastPlacedOrder'

export type AatriumPaymentGroupMeta = { [key in AatriumPaymentGroups]: AatriumPaymentGroupMetaItem }

export const AatriumPaymentGroupMetaConfig: AatriumPaymentGroupMeta = {
  [AatriumPaymentGroups.installment]: {
    label: 'Installment',
    type: 'installment'
  },
  [AatriumPaymentGroups.bankLink]: {
    label: 'Bank link payment',
    type: 'bank-payment'
  },
  [AatriumPaymentGroups.invoice]: {
    label: 'With invoice',
    type: 'courier'
  },
  [AatriumPaymentGroups.other]: {
    label: 'Other',
    type: 'other'
  }
}

export const AatriumPaymentMethodsReorderConfig = {
  [AatriumPaymentGroups.installment]: ['inbankpayment-1', 'inbankpayment-2'],
  [AatriumPaymentGroups.other]: [],
  [AatriumPaymentGroups.bankLink]: [],
  [AatriumPaymentGroups.invoice]: []
}
