import { PaymentMethodParams } from '../usePaymentProvider'
import { UseContextReturn } from '~/types/core'
import { AatriumAvailablePaymentMethod } from '~/modules/checkout/aatrium/types'

export const setPaymentMethodOnCartCommand = {
  execute: async (context: UseContextReturn, params: PaymentMethodParams): Promise<AatriumAvailablePaymentMethod[]> => {
    const { data } = await context.app.$vsf.$magento.api.setPaymentMethodOnCart(params, params?.customQuery ?? null)

    return data?.setPaymentMethodOnCart?.cart.available_payment_methods ?? []
  }
}
