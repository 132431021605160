import { ProductImagesProj } from './ProductImages.proj'

export const ProductMediaProj = `#graphql
${ProductImagesProj}
fragment ProductMediaProj on ProductInterface {
  media_gallery {
    ...ProductImagesProj
  }
  image {
    ...ProductImagesProj
  }
}
`
