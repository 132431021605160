import { useRoute, useRouter } from '@nuxtjs/composition-api'
import { useManagedNewsletterStore } from '../store/useManagedNewsletterStore'
import { ManagedNewsletter, UseManagedNewsletter } from '~/modules/managed-newsletter/types'
import { useUiState, useNewsletter } from '~/composables'
import { safeComputed } from '~/modules/helpers'
import { useUser } from '~/modules/customer/composables/useUser'

export function useManagedNewsletter (): UseManagedNewsletter {
  const managedNewsletterStore = useManagedNewsletterStore()
  const route = useRoute()
  const router = useRouter()
  const { updateSubscription, loading } = useNewsletter()
  const { isAuthenticated, user } = useUser()
  const { toggleManagedNewsletterModal, isManagedNewsletterModalOpen } = useUiState()

  const config = safeComputed<ManagedNewsletter>(() => managedNewsletterStore.config)
  const isLoading = safeComputed<boolean>(() => managedNewsletterStore.loading || loading.value)
  const targetPageNames = safeComputed<string[]>(() => {
    const paths = managedNewsletterStore.config?.showOnPages
      .split('\n')
      .map(r => r.trim())
      .filter(r => !!r) ??
      []
    return paths.map(i => router.resolve(i)?.resolved.name).filter(e => !!e)
  })
  const isTargetPage = safeComputed<boolean>(() => targetPageNames.value.includes(route.value.name))
  const isUnSubscribedUser = safeComputed<boolean>(() => !isAuthenticated.value || !user.value.is_subscribed)

  function openModal (): void {
    if (isManagedNewsletterModalOpen.value) {
      return
    }

    toggleManagedNewsletterModal()
    managedNewsletterStore.removeCloseTimeFromLc()
  }

  function closeModal (): void {
    if (!isManagedNewsletterModalOpen.value) {
      return
    }

    managedNewsletterStore.setCloseTimeToLc()
    toggleManagedNewsletterModal()
  }

  function isOpenAllowed (): boolean {
    const closedTime: number = managedNewsletterStore.getCloseTimeFromLc()
    const reopenTime: number = closedTime + config.value.reopenAfterClosedHours
    return (Date.now() > reopenTime) && isUnSubscribedUser.value
  }

  return {
    config,
    targetPageNames,
    isLoading,
    isTargetPage,
    isOpenModal: isManagedNewsletterModalOpen,
    openModal,
    closeModal,
    updateSubscription,
    isOpenAllowed
  }
}
