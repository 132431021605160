export enum CheckoutStep {
  contactInfo = 'contactInfo',
  shipping = 'shipping',
  payment = 'payment',
}

export const CHECKOUT_STEPS: CheckoutStep[] = [
  CheckoutStep.contactInfo,
  CheckoutStep.shipping,
  CheckoutStep.payment
]
