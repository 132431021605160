<template>
  <base-button
    :class="['search-btn', isOpen ? 'search-btn-close' : 'primary']"
    aria-label="search button"
    @click="$emit('click')"
  >
    <i :class="`icon-${!isOpen ? 'search' : 'close'}`" />
  </base-button>
</template>

<script>
import BaseButton from '~/components/BaseButton'

export default {
  name: 'SearchButton',
  components: {
    BaseButton
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.search-btn {
  --button-border-radius: 50%;

  &-close {
    --button-background: var(--c-light);

    i {
      &:before {
        color: var(--c-dark);
      }
    }

    &:hover {
      --button-background: var(--c-grey-1);
    }
  }
}
</style>
