import { Context } from '@nuxt/types'

let ctx: Context

declare global {
  interface Element {
    __vue__: any
  }
}

export function getAppContext (): Context | undefined {
  if (!process.server) {
    if (!ctx) {
      ctx = Array.from(document.querySelectorAll('*')).find(e => e.__vue__).__vue__.context
    }

    return ctx
  }

  return undefined
}
