import { computed, ComputedRef, ref, shallowReadonly } from '@nuxtjs/composition-api'
import { UseAdditionalProductsSearchInterface, AdditionalProduct } from './useAdditionalProductsSearch'
import { getAdditionalProductsSearchQuery } from './graphql/getAdditionalProductsSearchQuery'
import { useCart, useUiNotification } from '~/composables'
import { Logger } from '~/helpers/logger'
import { useApi } from '~/composables/useApi'
import { useI18n } from '~/helpers/hooks/usei18n'
import { GetProductSearchParams } from '~/modules/catalog/product/types'
import { UseProductErrors } from '~/modules/catalog/product/composables/useProduct'
import { CartItemInterface, Maybe } from '~/modules/GraphQL/types'

export function useAdditionalProductsSearch (skus: string[]): UseAdditionalProductsSearchInterface {
  const { query } = useApi()
  const { cart, removeItem } = useCart()
  const { send: sendNotification } = useUiNotification()
  const i18n = useI18n()

  const loading = ref<boolean>(false)
  const error = ref<UseProductErrors>(null)
  const result = ref<AdditionalProduct[] | []>([])

  const addedProductsInCart: ComputedRef<CartItemInterface[]> = computed((): CartItemInterface[] => {
    return cart.value?.items?.filter((i: Maybe<CartItemInterface>) => skus.includes(i.product.sku)) || []
  })
  const hasProductsInCart: ComputedRef<boolean> = computed(() => !!addedProductsInCart.value.length)

  const removeAllAdditionalProducts = async (): Promise<void> => {
    for (const product of addedProductsInCart.value) {
      await removeItem({ product })
    }
  }

  const search = async (): Promise<void> => {
    loading.value = true

    const productSearchParams: GetProductSearchParams = {
      pageSize: 20,
      filter: { sku: { in: [...skus] } }
    }

    try {
      const { data } = await query<{ products: { items: AdditionalProduct[] } }>(getAdditionalProductsSearchQuery, productSearchParams)

      error.value = null
      result.value = data?.products?.items ?? []

      if (!result.value.length) {
        sendNotification({
          id: Symbol('load-additional-products'),
          type: 'danger',
          title: i18n.t('Something went wrong'),
          persist: false,
          timeToLive: 5000
        })
      }
    } catch (err) {
      error.value = err
      Logger.error('useAdditionalProductsSearch/search', err)

      return err
    } finally {
      loading.value = false
    }
  }

  return {
    search,
    addedProductsInCart: shallowReadonly(addedProductsInCart),
    hasProductsInCart: shallowReadonly(hasProductsInCart),
    loading: shallowReadonly(loading),
    error: shallowReadonly(error),
    result: shallowReadonly(result),
    removeAllAdditionalProducts
  }
}
