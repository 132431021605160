import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10c15e7d = () => interopDefault(import('../modules/checkout/aatrium/pages/Checkout.vue' /* webpackChunkName: "" */))
const _5e8f3db1 = () => interopDefault(import('../modules/gdpr/pages/Gdpr.vue' /* webpackChunkName: "" */))
const _115548ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _bee9fd7c = () => interopDefault(import('../modules/catalog/aatrium/pages/Categories.vue' /* webpackChunkName: "" */))
const _e285bed2 = () => interopDefault(import('../modules/strapi/pages/ContactPage.vue' /* webpackChunkName: "" */))
const _ef326878 = () => interopDefault(import('../modules/customer/aatrium/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _558578ce = () => interopDefault(import('../modules/customer/aatrium/pages/MyAccount/AcceptanceDetails.vue' /* webpackChunkName: "" */))
const _459ffb5a = () => interopDefault(import('../modules/customer/aatrium/pages/MyAccount/OrderHistory.vue' /* webpackChunkName: "" */))
const _e8bf2150 = () => interopDefault(import('../modules/customer/aatrium/pages/MyAccount/PasswordChange.vue' /* webpackChunkName: "" */))
const _ec388e12 = () => interopDefault(import('../modules/customer/aatrium/pages/MyAccount/PersonalData.vue' /* webpackChunkName: "" */))
const _6eff17d7 = () => interopDefault(import('../modules/customer/aatrium/pages/MyAccount/ShippingDetails.vue' /* webpackChunkName: "" */))
const _b3c0e84e = () => interopDefault(import('../modules/customer/aatrium/pages/MyAccount/Subscriptions.vue' /* webpackChunkName: "" */))
const _113dd731 = () => interopDefault(import('../modules/customer/aatrium/pages/MyAccount/Order.vue' /* webpackChunkName: "" */))
const _478a714b = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _d3ff4e88 = () => interopDefault(import('../modules/checkout/aatrium/pages/PaymentFailed.vue' /* webpackChunkName: "" */))
const _0a594199 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _15b8f1ce = () => interopDefault(import('../modules/catalog/aatrium/pages/Search.vue' /* webpackChunkName: "" */))
const _6b0cb05e = () => interopDefault(import('../modules/test/pages/Test.vue' /* webpackChunkName: "" */))
const _e93f72e8 = () => interopDefault(import('../modules/checkout/aatrium/pages/ThankYou.vue' /* webpackChunkName: "" */))
const _3dba2ba6 = () => interopDefault(import('../modules/catalog/aatrium/pages/Campaigns.vue' /* webpackChunkName: "" */))
const _bea5d38c = () => interopDefault(import('../modules/dev/pages/KlevuProductCard.vue' /* webpackChunkName: "" */))
const _7ca22b40 = () => interopDefault(import('../modules/strapi/pages/StrapiPage.vue' /* webpackChunkName: "" */))
const _6b413439 = () => interopDefault(import('../modules/catalog/aatrium/pages/Product.vue' /* webpackChunkName: "" */))
const _4716fab8 = () => interopDefault(import('../modules/catalog/aatrium/pages/Category.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _10c15e7d,
    name: "checkout___default"
  }, {
    path: "/gdpr.html",
    component: _5e8f3db1,
    name: "gdpr-query___default"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home___default"
  }, {
    path: "/kategooriad.html",
    component: _bee9fd7c,
    name: "categories___default"
  }, {
    path: "/kontaktid.html",
    component: _e285bed2,
    name: "contacts___default"
  }, {
    path: "/my-account",
    component: _ef326878,
    name: "my-account___default",
    children: [{
      path: "gdpr-acceptance-details",
      component: _558578ce,
      name: "gdpr-acceptance-details___default"
    }, {
      path: "order-history",
      component: _459ffb5a,
      name: "order-history___default"
    }, {
      path: "password-change",
      component: _e8bf2150,
      name: "password-change___default"
    }, {
      path: "personal-data",
      component: _ec388e12,
      name: "personal-data___default"
    }, {
      path: "shipping-details",
      component: _6eff17d7,
      name: "shipping-details___default"
    }, {
      path: "subscriptions",
      component: _b3c0e84e,
      name: "loyalty-program-and-newsletter___default"
    }, {
      path: "order-history/:orderId",
      component: _113dd731,
      props: {},
      name: "order___default"
    }, {
      path: "*",
      component: _ec388e12,
      name: "not-found___default"
    }]
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page___default"
  }, {
    path: "/payment-fail",
    component: _d3ff4e88,
    name: "payment-failed___default"
  }, {
    path: "/reset-password",
    component: _0a594199,
    alias: "/createPassword",
    name: "reset-password___default"
  }, {
    path: "/search",
    component: _15b8f1ce,
    meta: {"keepScrollPosition":true},
    name: "search___default"
  }, {
    path: "/test",
    component: _6b0cb05e,
    name: "test___default"
  }, {
    path: "/thank-you",
    component: _e93f72e8,
    name: "thank-you___default"
  }, {
    path: "/c/kampaaniad.html",
    component: _3dba2ba6,
    meta: {"keepScrollPosition":true},
    name: "kampaaniad___default"
  }, {
    path: "/dev/klevu-product-card.html",
    component: _bea5d38c,
    name: "klevu-card-compilation___default"
  }, {
    path: "/",
    component: _115548ca,
    name: "home___default"
  }, {
    path: "/bank/:slug+.html",
    component: _7ca22b40,
    name: "bank___default"
  }, {
    path: "/info/:slug+.html",
    component: _7ca22b40,
    name: "info___default"
  }, {
    path: "/teenused/:slug+.html",
    component: _7ca22b40,
    name: "teenused___default"
  }, {
    path: "/p/:id/:slug?",
    component: _6b413439,
    name: "product___default"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _4716fab8,
    meta: {"keepScrollPosition":true},
    name: "category___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
