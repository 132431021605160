
import LazyHydrate from 'vue-lazy-hydration'
import AppMinimalHeader from '~/components/AppMinimalHeader.vue'
import AppMinimalFooter from '~/components/AppMinimalFooter.vue'
import LayoutSidebarsPortal from '~/components/LayoutSidebarsPortal.vue'

export default {
  name: 'MinimalLayout',
  components: {
    LayoutSidebarsPortal,
    LazyHydrate,
    AppMinimalHeader,
    AppMinimalFooter,
    Notifications: () => import(/* webpackPrefetch: true */ '~/components/Notifications.vue')
  },
  setup () {

  }
}
