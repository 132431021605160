import { generateMobileIdCode } from '../graphql';
import { EstAuthCustomerType, EstAuthMobileIdCodeInput, IEstAuthMethod } from '../types';
import { EstAuthError } from '../authError';
import { AuthSidebarMode } from "../../useUiState/enums/AuthSidebarMode";
import { VsfContext } from "../../context";
import { FetchResult } from "@apollo/client/core";

export default (context: VsfContext, customerType: EstAuthCustomerType): IEstAuthMethod => {

  const generateCode = async (input: EstAuthMobileIdCodeInput, mode: AuthSidebarMode,) => {
    const { data, errors } = await context.$magento.api.customMutation({
      mutation: generateMobileIdCode,
      mutationVariables: input
    }) as FetchResult<any>;

    if (errors || !data?.generateMobileIdCode || !data?.generateMobileIdCode.code) {
      throw new EstAuthError();
    }

    return { code: data.generateMobileIdCode.code };
  };

  return {
    generateCode
  };
}
