<template>
  <footer class="minimal-footer">
    <copyright />
  </footer>
</template>

<script>
import Copyright from '~/components/Footer/Copyright'

export default {
  name: 'AppMinimalFooter',
  components: { Copyright }
}
</script>

<style lang="scss" scoped>
.minimal-footer {
  background: var(--c-dark);
  border-top: 2px solid var(--c-grey);
}
</style>
