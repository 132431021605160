const singletonInstance: { [key: string]: any } = {}

export function useAsSingleton<T> (key: string, composable: () => T): () => T {
  return () => {
    if (!singletonInstance[key]) {
      singletonInstance[key] = composable()
    }

    return singletonInstance[key]
  }
}
