
import { ref, defineComponent, onMounted, onUnmounted } from '@nuxtjs/composition-api'
import { useI18n } from '~/helpers/hooks/usei18n'
import { useUiState } from '~/composables'

import BaseButton from '~/components/BaseButton.vue'

export default defineComponent({
  name: 'AppMaintenance',
  components: { BaseButton },
  setup () {
    const i18n = useI18n()
    const { changeToErrorPageView, changeToDefaultPageView } = useUiState()

    const imgName = ref('maintenance')
    const imgLink = ref('/error/maintenance.webp')
    const heading = ref(i18n.t('Hang on!'))
    const subHeading = ref(i18n.t('We are under maintenance'))
    const description = ref(i18n.t('We’ll be back shortly'))

    onMounted(() => {
      changeToErrorPageView()
    })

    onUnmounted(() => {
      changeToDefaultPageView()
    })

    return {
      imgName,
      imgLink,
      heading,
      subHeading,
      description
    }
  },
  head () {
    return {
      title: 'Maintenance - page'
    }
  }
})
