import { ref, useRoute, useRouter } from '@nuxtjs/composition-api'
import { cloneDeep, isEqual, unset } from 'lodash-es'
import { FilterParams, Params, QueryParams } from './Params'
import { useConfig, UseUiHelpersInterface } from '~/composables'
import { CategoryTree } from '~/modules/GraphQL/types'
import { FacetInterface } from '~/modules/catalog/category/types'
import { FilterQueryParams } from '~/composables/useUiHelpers/types'
import { DEFAULT_QUERY_VALUES } from '~/composables/useUiHelpers/variables'
import { useExtraRouter } from '~/composables/useExtraRouter'
import { defaultSearchSortOption } from '~/modules/catalog/category/variables/defaultSearchSortOption'

const nonFilters = new Set(['page', 'sort', 'term', 'itemsPerPage'])
const filtersParamPrefix: string = 'f.'
const filtersParamPrefixRegex: RegExp = new RegExp(filtersParamPrefix.replace(/\./g, '\\.'), 'g')

/**
 * Allows handling the parameters for filtering,
 * searching, sorting and pagination in the URL search/query params.
 *
 * See the {@link UseUiHelpersInterface} for a list of methods and values available in this composable.
 */

export function useUiHelpers (): UseUiHelpersInterface {
  const extraRouter = useExtraRouter()
  const route = useRoute()
  const router = useRouter()
  const { config } = useConfig()
  const defaultQueryParams = ref(cloneDeep(DEFAULT_QUERY_VALUES))

  let { query: routerQuery } = route.value

  function resolveQuery (): QueryParams {
    if (typeof window !== 'undefined') {
      routerQuery = router.resolve((window.location.pathname + window.location.search).slice(1)).route.query
    }

    return routerQuery
  }

  /**
   * Checks if is key its filter query key
   * @param key
   */
  function isFilterQueryKey (key: string): boolean {
    return key.startsWith(filtersParamPrefix) && key.match(filtersParamPrefixRegex)?.length === 1
  }

  function getFiltersDataFromUrl (onlyFilters: boolean = false): QueryParams | FilterParams {
    const currentQuery = resolveQuery()
    return (
      Object.keys(currentQuery)
        .filter(key => (isFilterQueryKey(key)) || nonFilters.has(key))
        .filter(f => (onlyFilters ? !nonFilters.has(f) : f))
        // eslint-disable-next-line unicorn/prefer-object-from-entries
        .reduce(reduceFilters(currentQuery), {})
    )
  }

  function nonFiltersQuery (): QueryParams {
    const currentQuery = resolveQuery()
    return Object.fromEntries(
      Object.entries(currentQuery).filter(([key]) => !isFilterQueryKey(key) && !nonFilters.has(key))
    )
  }

  function getFacetsFromURL (): Params {
    const currentQuery = resolveQuery()

    return {
      filters: getFiltersDataFromUrl(true) as FilterParams,
      itemsPerPage: Number.parseInt(currentQuery.itemsPerPage, 10) || config.value.list_per_page || 32,
      page: Number.parseInt(currentQuery.page, 10) || 1,
      sort: currentQuery.term ? currentQuery.sort ?? defaultSearchSortOption.sortCode : currentQuery.sort ?? '',
      term: currentQuery.term
    }
  }

  function changeSearchTerm (term: string): string {
    return term
  }

  function getSearchTermFromUrl (): Params {
    const currentQuery = resolveQuery()

    return {
      page: Number.parseInt(currentQuery.page, 10) || 1,
      sort: currentQuery.term ? currentQuery.sort ?? defaultSearchSortOption.sortCode : currentQuery.sort ?? '',
      filters: getFiltersDataFromUrl(true) as FilterParams,
      itemsPerPage: Number.parseInt(currentQuery.itemsPerPage, 10) || 10,
      term: currentQuery.term
    }
  }

  function getCatLink (category: CategoryTree): string {
    return `/c/${category.url_path}${category.url_suffix || ''}`
  }

  /**
   * Force push for a backward compatibility in other places, should be removed
   *
   * @param sort
   * @param forcePush
   */
  async function changeSorting (sort: string, forcePush = true): Promise<void> {
    const query = {
      ...getFiltersDataFromUrl(),
      sort
    }

    await applyFilterQueryParams(query, forcePush)
  }

  /**
   * Force push for a backward compatibility in other places, should be removed
   *
   * @param filters
   * @param forcePush
   */
  async function changeFilters (filters: FilterParams, forcePush = true): Promise<void> {
    const query = {
      ...getFiltersDataFromUrl(false),
      ...filters
    }

    await applyFilterQueryParams(query, forcePush)
  }

  async function removeFilters (filter: string, value: string): Promise<void> {
    const filters = getFiltersDataFromUrl(false)

    if (Array.isArray(filters[filter]) && filters[filter]?.includes(value)) {
      filters[filter] = (filters[filter] as string[]).filter(f => f !== value)
    }
    await applyFilterQueryParams(filters, false)
  }

  async function removeFiltersByName (filter: string): Promise<void> {
    const filters = getFiltersDataFromUrl(false)
    unset(filters, filter)
    await applyFilterQueryParams(filters, false)
    // const routeData = router.resolve({ query: filters })
    // window.history.pushState({}, null, routeData.href)
  }

  async function clearFilters (forcePush = true): Promise<void> {
    await applyFilterQueryParams({}, forcePush)
  }

  /**
   * Force push for a backward compatibility in other places, should be removed
   *
   * @param itemsPerPage
   * @param forcePush
   */
  async function changeItemsPerPage (itemsPerPage: number, forcePush = true): Promise<void> {
    const query = {
      ...getFiltersDataFromUrl(false),
      itemsPerPage: itemsPerPage.toString(10)
    }

    await applyFilterQueryParams(query, forcePush)
  }

  async function changePage (page: number, forcePush = true): Promise<void> {
    const query = {
      ...getFiltersDataFromUrl(false),
      page: page.toString()
    }

    await applyFilterQueryParams(query, forcePush)
  }

  async function setTermForUrl (term: string): Promise<void> {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        term: term || undefined
      }
    })
  }

  function isFacetColor (facet: FacetInterface): boolean {
    return facet.id === 'color'
  }

  function isFacetCheckbox (): boolean {
    return false
  }

  function addParamsToDefaultList (params: Record<string, string | string[] | number | number[]>): void {
    defaultQueryParams.value = {
      ...defaultQueryParams.value,
      ...params
    }
  }

  /**
   * Serialize filter query key, apply prefix to filter if it needs
   * @param value
   */
  function serializeFilterQueryKey (value: string): string {
    return (nonFilters.has(value))
      ? value
      : `${filtersParamPrefix}${value}`
  }

  /**
   * Deserialize filter query key, removes prefix to filter if it needs
   * @param value
   */
  function deserializeFilterQueryKey (value: string): string {
    return (nonFilters.has(value))
      ? value
      : (isFilterQueryKey(value)) ? value.replace(filtersParamPrefix, '') : ''
  }

  /**
   * Serialize filter query value to string,or easy comparison of primitives level
   * @param value
   */
  function serializeFilterQueryValue (value: string | number | Array<string | number>): string {
    return (Array.isArray(value)) ? value.join(',') : value?.toString() || null
  }

  /**
   * Transform custom FilterQueryParams to Dictionary<string | string[]> for nuxt router navigate methods
   * @param query
   */
  function filterQueryToDictionary (query: FilterQueryParams): Record<string, string | string[]> {
    return Object.entries(query).reduce((dic, [key, value]) => {
      dic[key] = (Array.isArray(value)) ? value.map(v => v?.toString()) : value?.toString() || null
      return dic
    }, {})
  }

  function getFilteredQuery (query: FilterQueryParams): FilterQueryParams {
    return Object.keys(query).reduce((filteredQuery: FilterQueryParams, key: string) => {
      const defaultValue = serializeFilterQueryValue(defaultQueryParams.value[key])
      const queryValue = serializeFilterQueryValue(query[key])
      if (queryValue && !isEqual(defaultValue, queryValue)) {
        filteredQuery[serializeFilterQueryKey(key)] = query[key]
      }
      return filteredQuery
    }, {})
  }

  async function applyFilterQueryParams (query: FilterQueryParams, forcePush: boolean): Promise<void> {
    const filteredQuery = getFilteredQuery(query)
    const newQueryParams = {
      ...nonFiltersQuery(),
      ...filterQueryToDictionary(filteredQuery)
    }
    if (forcePush) {
      await router.push({ query: newQueryParams })
    } else {
      extraRouter.setQueryParamsWithoutReloadRoute(newQueryParams)
    }
  }

  function reduceFilters (query: QueryParams) {
    return (prev: FilterParams, curr: string): FilterParams => {
      const makeArray = Array.isArray(query[curr]) || nonFilters.has(curr)
      const deserializedFilterKey = deserializeFilterQueryKey(curr)

      return {
        ...prev,
        [deserializedFilterKey]: makeArray ? query[curr] as string[] : [query[curr] as string]
      }
    }
  }

  return {
    changeFilters,
    changeItemsPerPage,
    changeSearchTerm,
    changeSorting,
    clearFilters,
    getCatLink,
    getFacetsFromURL,
    getSearchTermFromUrl,
    isFacetCheckbox,
    isFacetColor,
    setTermForUrl,
    changePage,
    removeFilters,
    removeFiltersByName,
    addParamsToDefaultList,
    applyFilterQueryParams,
    getFiltersDataFromUrl
  }
}

export * from './Params'
export * from './useUiHelpers'
// eslint-disable-next-line import/no-default-export
export default useUiHelpers
