export enum AatriumStrapiSectionTypes {
  SingleBanner = 'sections.hero',
  CategoryProducts = 'sections.product-widget',
  Newsletter = 'sections.newsletter',
  Notice = 'sections.cms-block1',
  ContactColumns = 'sections.three-columns-textarea',
  ContactDepartments = 'sections.departments'
}
//
// export const AatriumStrapiSectionTypes = {
//   SingleBanner: 'sections.hero',
//   CategoryProducts: 'sections.product-widget'
// }
