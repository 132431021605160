// eslint-disable-next-line import/no-default-export
export default function scrollBehavior (_to, _from, savedPosition) {
  // Save scroll position when only query params are changed and enabled keepScrollPosition param
  if (!process.server && _to.path === _from.path && _to.meta.keepScrollPosition) {
    return
  }

  return savedPosition || {
    x: 0,
    y: 0
  }
}
