import { StrapiProducts, StrapiSection, WrappedPrepareContentPageSectionFnFactory } from '@zaprooecommerce/strapi'
import { StrapiSectionProducts } from '~/modules/strapi/types'
import { Products } from '~/modules/GraphQL/types'
import { useProduct } from '~/modules/catalog/product/composables/useProduct'

export function prepareProductsWidget (): WrappedPrepareContentPageSectionFnFactory<StrapiSectionProducts> {
  const { getProductList } = useProduct()

  return async (section: StrapiSection, preparedSection: StrapiSectionProducts) => {
    const products: StrapiProducts = section.products
    if (products?.skuList !== undefined) {
      const fetchedProducts = await getProductList({ filter: { sku: { in: products?.skuList } } }) as Products
      preparedSection.fetchedProducts = fetchedProducts?.items || []
    }

    return preparedSection
  }
}
