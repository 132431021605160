import { defineStore } from 'pinia'
import { prepareConfig } from '../helpers/prepareConfig'
import { ManagedNewsletter, ManagedNewsletterStore } from '../types'
import { MANAGED_NEWSLETTER_CLOSE_TIME_LC_KEY } from '../variables'
import { Logger } from '~/helpers/logger'
import { useStrapi } from '~/modules/strapi/composables/useStrapi'

export const useManagedNewsletterStore = defineStore('managed-newsletter', {
  state: (): ManagedNewsletterStore => ({
    loading: false,
    config: undefined
  }),
  actions: {
    async fetch (): Promise<void> {
      const { find } = useStrapi()
      this.loading = true

      try {
        const res = await find<ManagedNewsletter>('newsletter')

        if (!res) {
          throw new Error('Data of type "newsletter" from strapi is empty')
        }

        this.config = prepareConfig(res)
      } catch (err) {
        Logger.error('Error loading "newsletter" single type from strapi:', err)
      } finally {
        this.loading = false
      }
    },
    getCloseTimeFromLc (): number {
      return +localStorage.getItem(MANAGED_NEWSLETTER_CLOSE_TIME_LC_KEY) ?? 0
    },
    setCloseTimeToLc (): void {
      localStorage.setItem(MANAGED_NEWSLETTER_CLOSE_TIME_LC_KEY, Date.now().toString())
    },
    removeCloseTimeFromLc (): void {
      localStorage.removeItem(MANAGED_NEWSLETTER_CLOSE_TIME_LC_KEY)
    }
  }
})
