import { defineAsyncComponent } from 'vue-demi'
import { AsyncComponent } from 'vue'
import { StateInterface } from '~/composables'

export const LAYOUTS_SIDEBARS: SidebarLayoutConfig = {
  AuthSidebar: {
    component: defineAsyncComponent(async () => await import(/* webpackPrefetch: true */ '~/components/AuthSidebar.vue')),
    isVisibleKey: 'isAuthSidebarOpen'
  },
  MenuSidebar: {
    component: defineAsyncComponent(async () => await import(/* webpackPrefetch: true */ '~/modules/mega-menu/components/MenuSidebar.vue')),
    isVisibleKey: 'isMegaMenuSidebarOpen'
  },
  CartSidebar: {
    component: defineAsyncComponent(async () => await import(/* webpackPrefetch: true */ '~/modules/checkout/aatrium/components/CartSidebar.vue')),
    isVisibleKey: 'isCartSidebarOpen'
  },
  MyAccountSidebar: {
    component: defineAsyncComponent(async () => await import('~/modules/customer/aatrium/components/MyAccountSidebar.vue')),
    isVisibleKey: 'isMyAccountSidebarOpen'
  },
  AccountDeletionSidebar: {
    component: defineAsyncComponent(async () => await import('@/modules/customer/aatrium/components/AccountDeletionSidebar.vue')),
    isVisibleKey: 'isAccountDeletionSidebarOpen'
  },
  WishlistSidebar: {
    component: defineAsyncComponent(async () => await import('~/modules/wishlist/aatrium/components/WishlistSidebar.vue')),
    isVisibleKey: 'isWishlistSidebarOpen'
  }
}

export interface SidebarLayoutConfig {
  [key: string]: {
    component: AsyncComponent
    isVisibleKey: keyof StateInterface
  }
}
