import { FetchResult } from '@apollo/client/core'
import { generateSmartIdCode } from '../graphql'
import { EstAuthCustomerType, EstAuthSmartIdCodeInput, IEstAuthMethod } from '../types'
import { EstAuthError } from '../authError'
import { VsfContext } from '../../context'
import { AuthSidebarMode } from '../../useUiState/enums/AuthSidebarMode'
import { Code } from '~/composables/useEstAuth/interfaces/Code'

// eslint-disable-next-line import/no-default-export
export default (context: VsfContext, customerType: EstAuthCustomerType): IEstAuthMethod => {
  async function generateCode (input: EstAuthSmartIdCodeInput, mode: AuthSidebarMode): Promise<Code> {
    const { data, errors } = await context.$magento.api.customMutation({
      mutation: generateSmartIdCode,
      mutationVariables: input
    }) as FetchResult<any>

    if (errors || !data?.generateSmartIdCode || !data?.generateSmartIdCode.code) {
      throw new EstAuthError()
    }

    return { code: data.generateSmartIdCode.code }
  }

  return {
    generateCode
  }
}
