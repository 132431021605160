
import { computed, defineComponent, useContext, PropType } from '@nuxtjs/composition-api'
import { NuxtError } from '@nuxt/types'

import Maintenance from './Maintenance.vue'
import NotFound from '~/modules/not-found-page-wrapper/components/NotFound.vue'

export default defineComponent({
  name: 'AppError',
  components: { Maintenance, NotFound },
  layout: 'error',
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      default: () => ({
        setStatus: true,
        statusCode: 404
      })
    }
  },
  setup (props) {
    const { ssrContext } = useContext()

    const is404 = computed(() => props.error?.statusCode === 404)

    if (process.server && props.error?.statusCode && props.error.statusCode !== ssrContext.res.statusCode) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      ssrContext.res.statusCode = props.error.statusCode
    }

    return {
      is404
    }
  }
})
