import { getAppContext } from '~/helpers/getAppContext'

export function event (event: string, params: any): void {
  const ctx: any = getAppContext()

  if (ctx) {
    if (params.ecommerce) {
      ctx?.$gtm?.push({ ecommerce: null })
    }

    ctx?.$gtm?.push({ event, ...params })
  }
}
