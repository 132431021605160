const middleware = {}

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['redirect-to-personal-details'] = require('../middleware/redirect-to-personal-details.ts')
middleware['redirect-to-personal-details'] = middleware['redirect-to-personal-details'].default || middleware['redirect-to-personal-details']

export default middleware
