export enum GtagProductActions {
  AddToWishlist = 'add_to_wishlist',
  AddToCart = 'add_to_cart',
  ViewCart = 'view_cart',
  ViewItem = 'view_item',
  RemoveFromCart = 'remove_from_cart'
}

export enum GtagPaymentActions {
  Purchase = 'purchase',
  Refund = 'refund'
}

export enum GtagItemListActions {
  SelectItem = 'select_item',
  ViewItemList = 'view_item_list'
}

// TODO: Can be implemented when task https://zaproo.atlassian.net/browse/AT-645 will be in LIVE
export enum GtagPromotionActions {
  SelectPromotion = 'select_promotion',
  ViewPromotion = 'view_promotion'
}

export enum GtagCheckoutActions {
  AddPaymentInfo = 'add_payment_info',
  AddShippingInfo = 'add_shipping_info',
  BeginCheckout = 'begin_checkout'
}
