
import { computed } from '@nuxtjs/composition-api'
import { useUiState } from '~/composables'
import { useUser } from '~/modules/customer/composables/useUser'
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore'

import HeaderButton from '~/components/Header/Buttons/HeaderButton.vue'

export default {
  name: 'WishlistButton',
  components: {
    HeaderButton
  },
  setup () {
    const wishlistStore = useWishlistStore()
    const { toggleWishlistSidebar } = useUiState()
    const { isAuthenticated } = useUser()

    const totalItems = computed<number>(() => wishlistStore.wishlist?.items_count ?? 0)
    const hasItems = computed<boolean>(() => totalItems.value > 0)

    return {
      isAuthenticated,
      totalItems,
      hasItems,
      toggleWishlistSidebar
    }
  }
}
