import { defineStore } from 'pinia'
import { ref, Ref } from '@nuxtjs/composition-api'
import { ProductInterface, useApi, useUiNotification } from '~/composables'
import { Products } from '~/composables/useAssemblyProductsList/graphql/types'
import { getAssemblyProductsQuery } from '~/composables/useAssemblyProductsList/graphql/getAssemblyProductsQuery'
import { useI18n } from '~/helpers/hooks/usei18n'
import { Logger } from '~/helpers/logger'
import { UseAssemblyProductsStore } from '~/composables/useAssemblyProductsList/interfaces/UseAssemblyProducts'

export const useAssemblyProductsStore = defineStore('assembly-products', (): UseAssemblyProductsStore => {
  const { query } = useApi()
  const { send: sendNotification } = useUiNotification()
  const i18n = useI18n()

  const products: Ref<ProductInterface[]> = ref([])
  const error = ref<Error>(null)
  const loading = ref<boolean>(false)

  const load = async (force?: boolean): Promise<ProductInterface[]> => {
    if (!force && products.value.length) {
      return
    }

    try {
      const { data } = await query<{ getAssemblyProducts: Products }>(getAssemblyProductsQuery)
      products.value = data?.getAssemblyProducts?.items ?? []

      if (!products.value.length) {
        sendNotification({
          id: Symbol('load-assembly-products'),
          type: 'danger',
          title: i18n.t('Something went wrong'),
          persist: false,
          timeToLive: 5000
        })
      }
    } catch (err) {
      error.value = err
      products.value = []
      Logger.error('useAssemblyProductsList/load', err)
    } finally {
      loading.value = false
    }

    return products.value
  }

  return {
    products,
    load,
    loading,
    error
  }
})
