
import { computed } from '@nuxtjs/composition-api'
import HeaderButton from './HeaderButton.vue'
import cartGetters from '~/modules/checkout/getters/cartGetters'
import { useUiState } from '~/composables'
import { useCart } from '~/modules/checkout/composables/useCart'

export default {
  name: 'CartButton',
  components: { HeaderButton },
  setup () {
    const { toggleCartSidebar } = useUiState()
    const { cart } = useCart()

    const totalItems = computed(() => cartGetters.getTotalItems(cart.value))
    const hasItems = computed(() => totalItems.value > 0)

    return {
      totalItems,
      hasItems,
      toggleCartSidebar
    }
  }
}
