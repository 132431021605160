const accountAttributeMap = {
  account_type: 'type',
  firstname: 'firstName',
  lastname: 'lastName',
  company_name: 'companyName',
  company_role: 'companyRole'
};

const getToken = ({ token }) => {
  return token;
};

const getFirstName = ({ data }) => {
  const fallbackValue = 'Firstname';

  if (!data) return fallbackValue;

  return data.find(d => d.key === 'firstname')?.value || fallbackValue;
};

const getLastName = ({ data }) => {
  const fallbackValue = 'Lastname';

  if (!data) return fallbackValue;

  return data.find(d => d.key === 'lastname')?.value || fallbackValue;
};

const getIdentityNumber = ({data}) => {
  return data.find(d => d.key === 'id_number')?.value;
}

const getAccounts = ({ accounts }) => {
  if (!accounts) return [];

  accounts = accounts.map(account => {
    account = account.value.reduce((acc, val) => {
      const key = accountAttributeMap[val.key] ?? val.key;

      acc[key] = val.value;

      return acc;
    }, {});

    return account;
  });

  return accounts;
};

const isMultiAccount = (result) => {
  return getAccounts(result).length > 1;
};

const getPersonalAccount = (result) => {
  return getAccounts(result).find(account => account.type === 'customer');
};

const getBusinessAccount = (result, email) => {
  return getAccounts(result).find(account => account.email === email);
};

export {
  getFirstName,
  getLastName,
  getAccounts,
  isMultiAccount,
  getPersonalAccount,
  getBusinessAccount,
  getToken,
  getIdentityNumber
};
