import { onMounted, shallowRef, useRoute, useRouter } from '@nuxtjs/composition-api'
import { debounce } from 'lodash-es'
import { QueryParams, UseExtraRouter } from '~/composables'
import { useAsSingleton } from '~/composables/useAsSingleton'

let proxyReady: boolean = false
export const useExtraRouter = useAsSingleton('extra-router', (): UseExtraRouter => {
  const router = useRouter()
  const route = useRoute()
  const query = shallowRef<QueryParams>(getQueryFromLocation())
  const href = shallowRef<string>((process.server) ? route.value.fullPath : document.location.href)

  const locationChangeHandler = debounce(() => {
    query.value = getQueryFromLocation()
    href.value = document.location.href
  }, 100)

  function setQueryParamsWithoutReloadRoute (query: QueryParams): void {
    const currentRouteData = router.resolve(`${window.location.pathname}${window.location.search}`)
    const routeData = router.resolve({ query })
    if (currentRouteData.resolved.fullPath !== routeData.resolved.fullPath) {
      window.history.pushState({}, null, routeData.href)
    }
  }

  function getQueryFromLocation (): QueryParams {
    let { query: routerQuery } = route.value
    if (typeof window !== 'undefined') {
      routerQuery = router.resolve((window.location.pathname + window.location.search).slice(1)).route.query
    }

    return routerQuery
  }

  function initProxy (): void {
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray) => {
        const result = target.apply(thisArg, argArray)
        window.dispatchEvent(new Event('pushState'))
        return result
      }
    })
  }

  // onMounted calls only in client side
  onMounted(() => {
    window.addEventListener('pushState', locationChangeHandler)
    setInterval(() => {
      const newValue = document.location.href
      if (href.value !== newValue) {
        locationChangeHandler()
      }
    }, 250)
  })

  if (!proxyReady && !process.server) {
    initProxy()
    proxyReady = true
  }

  return {
    query,
    href,
    getQueryFromLocation,
    setQueryParamsWithoutReloadRoute
  }
})
