import { cloneDeep } from 'lodash-es'
import { Breadcrumb, MediaGalleryItem, Price, ProductStoreSourceCode } from '~/modules/catalog/types'
import { Product, ProductAttribute } from '~/modules/catalog/product/types'
import { BundleProduct, CategoryInterface, CategoryTree, GroupedProduct, PriceRange, ProductInterface, UrlRewrite } from '~/modules/GraphQL/types'
import { htmlDecode } from '~/helpers/htmlDecoder'
import { getAverageRating, getTotalReviews } from '~/modules/review/getters/reviewGetters'
import { ProductGetters } from '~/modules/catalog/product/getters/interfaces/ProductGetters'
import { PRODUCT_STORE_CODES } from '~/modules/catalog/aatrium/variables/productStoreCodes'
import { createProductPath } from '~/modules/product-correct-url/helpers'

export function getName (product: ProductInterface): string {
  if (!product) {
    return ''
  }

  return htmlDecode(product.name)
}

export function getSlug (product: ProductInterface | { sku: string, url_key: string, url_rewrites?: UrlRewrite[] }, category?: CategoryTree | CategoryInterface): string {
  const rewrites = product?.url_rewrites

  if (!rewrites?.length && product.url_key) {
    return `/${product.url_key}${product.url_key.endsWith('.html') ? '' : '.html'}`
  } else if (rewrites?.length) {
    return `/${rewrites[0].url}`
  }

  // eslint-disable-next-line no-console
  console.warn('getSlug can\'t extract slug from product')

  return ''
}

export function getPrice (product: { price_range: PriceRange }): Price {
  let regular = 0
  let special = null
  let maximum = null
  let final = null
  if (product?.price_range) {
    regular = product?.price_range?.minimum_price?.regular_price?.value
    final = product?.price_range?.minimum_price?.final_price?.value
    maximum = product?.price_range?.maximum_price?.final_price?.value

    if (final < regular) {
      special = final
    }
  }

  return {
    regular,
    special,
    maximum,
    final
  }
}

export function getGallery (product: Product, maxGallerySize = undefined): MediaGalleryItem[] {
  const images = []

  if (!product?.media_gallery.length && !product?.configurable_product_options_selection?.media_gallery.length) {
    return images
  }

  const selectedGallery = product.configurable_product_options_selection?.media_gallery.length
    ? product.configurable_product_options_selection.media_gallery
    : product.media_gallery

  // eslint-disable-next-line no-restricted-syntax
  for (const galleryItem of selectedGallery) {
    images.push({
      small: galleryItem.url,
      normal: galleryItem.url,
      big: galleryItem.url,
      position: galleryItem?.position
    })
  }

  return maxGallerySize ? images.slice(0, maxGallerySize) : images
}

export function getCoverImage (product: Product): string {
  if (!product?.image) {
    return '/productpage/placeholder.webp'
  }

  return product.image.url
}

export function getProductThumbnailImage (product: Product): string {
  if (!product?.thumbnail) {
    return null
  }

  return product.thumbnail.url
}

export function getAttributes (
  products: Product,
  _filterByAttributeName?: string[]
): Record<string, ProductAttribute | string> {
  if (!products?.configurable_options) {
    return {}
  }

  const attributes = {}
  const configurableOptions = products.configurable_options

  // eslint-disable-next-line no-restricted-syntax
  for (const option of configurableOptions) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    attributes[option.attribute_code] = {
      code: option.attribute_code,
      label: option.label,
      value: option.values.map((value) => {
        const obj = {}
        obj[value.value_index] = value.label
        return obj
      })
    } as ProductAttribute
  }
  return attributes
}

export function getDescription (product: Product): string {
  if (!product?.description) {
    return ''
  }

  return product.description.html
}

export function getShortDescription (product: Product): string {
  if (!product?.short_description) {
    return ''
  }
  return product.short_description.html
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getCategoryIds (product: Product): string[] {
  const categoryIds = []

  if (!product?.categories) {
    return categoryIds
  }

  return product.categories.map(category => category.uid)
}

export function getCategory (product: any, currentUrlPath: string): CategoryTree | null {
  if (!product?.categories || product?.categories.length === 0) {
    return null
  }

  const categories = currentUrlPath.split('/')
  categories.pop()

  if (categories.length === 0) {
    return null
  }

  const categoryPath = categories.join('/')

  // eslint-disable-next-line no-restricted-syntax
  for (const category of product.categories) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    if (`/${category.url_path}` === categoryPath) {
      return category
    }
  }

  return null
}

export function getCategoryName (product: Product): string | null {
  if (!product?.categories || product?.categories.length === 0) {
    return null
  }

  const categories: CategoryInterface[] = cloneDeep(product.categories)

  return categories.pop().name
}

export function getId (product: Product | { uid: string }): string {
  return product.uid
}

export function getMagentoId (product: Product | ProductInterface | { id: number }): number | undefined {
  return product.id
}

export function getProductSku (product: Product | ProductInterface | { sku: string }): string {
  return product.sku
}

// eslint-disable-next-line no-underscore-dangle
export function getTypeId (product: Product): string {
  return product.__typename
}

function getCategoryBreadcrumbs (category: CategoryInterface): Breadcrumb[] {
  let breadcrumbs: Breadcrumb[] = []

  if (!category) {
    return []
  }

  if (Array.isArray(category?.breadcrumbs)) {
    breadcrumbs = category.breadcrumbs.map(breadcrumb => ({
      text: breadcrumb.category_name,
      link: `/c/${breadcrumb.category_url_path}${category.url_suffix || ''}`
    }))
  }

  breadcrumbs.push({
    text: category.name,
    link: `/c/${category.url_path}${category.url_suffix || ''}`
  })

  return breadcrumbs
}

export function getBreadcrumbs (product: ProductInterface, category?: CategoryInterface): Breadcrumb[] {
  let breadcrumbs = []

  if (!product) {
    return breadcrumbs
  }

  if (category) {
    breadcrumbs = getCategoryBreadcrumbs(category)
  }

  breadcrumbs.push({
    text: getName(product),
    link: createProductPath(getProductSku(product), getSlug(product))
  })

  return breadcrumbs
}

export { getTotalReviews, getAverageRating } from '~/modules/review/getters/reviewGetters'

export function getProductRelatedProduct (product: any): Product[] {
  return product?.related_products || []
}

export function getProductUpsellProduct (product: any): Product[] {
  return product?.upsell_products || []
}

export function getSwatchData (swatchData: Product['configurable_options'][0]['values'][0]['swatch_data']): string | undefined {
  return swatchData?.value
}

function sortProduct (a, b): number {
  return a.position - b.position
}

export function getGroupedProducts (product: GroupedProduct & { __typename: string }): GroupedProduct['items'] | undefined {
  return product.__typename === 'GroupedProduct' && product?.items?.sort(sortProduct)
}

export function getBundleProducts (product: BundleProduct & { __typename: string }): BundleProduct['items'] | undefined {
  return product.__typename === 'BundleProduct' && product?.items?.sort(sortProduct)
}

export function isInStock (product: Product): boolean {
  return product?.availability_in_stores?.some(item => (
    PRODUCT_STORE_CODES.includes(item.source_code as ProductStoreSourceCode) && !!item.status && !!item.qty
  ))
}

export function isInShowroomAvailable (product: Product): boolean {
  return product.availability_in_stores.some(item => (
    item.source_code === ProductStoreSourceCode.MUUGISAAL && !!item.status && !!item.qty
  ))
}

export function isOrderMoreAvailable (product: Product): string | false {
  if (product.tarneaeg && isBackorderAvailable(product)) {
    return product.tarneaeg
  }

  return false
}

export function isBackorderAvailable (product: Product): boolean {
  // eslint-disable-next-line prefer-regex-literals
  return !['müük ainult laoseisust'].includes(product.tarneaeg?.replace(new RegExp('\u0075\u0308', 'g'), 'ü'))
}

export function getTotalStoreAvailability (product: Product): number {
  const relevantStores = product?.availability_in_stores.filter(store => (
    PRODUCT_STORE_CODES.includes(store.source_code as ProductStoreSourceCode) && !!store.qty && !!store.status
  ))

  return relevantStores.reduce((acc, store) =>
    (acc + (store.source_code === ProductStoreSourceCode.MUUGISAAL ? store.qty - 1 : store.qty)), 0
  )
}

export function getAvailabilityInShowroom (product: Product): number {
  const showroomStore = product?.availability_in_stores.find(store => store.source_code === ProductStoreSourceCode.MUUGISAAL)
  return showroomStore.qty && showroomStore.status ? showroomStore.qty - 1 : 0
}

export function getAvailableQuantity (product: Product): number {
  const relevantStores = product?.availability_in_stores.filter(store => (
    PRODUCT_STORE_CODES.includes(store.source_code as ProductStoreSourceCode) && !!store.status
  ))

  return relevantStores.reduce((acc, store) =>
    (acc + store.qty), 0
  )
}

const productGetters: ProductGetters = {
  getAttributes,
  getAverageRating,
  getBreadcrumbs,
  getCategory,
  getCategoryIds,
  getCategoryName,
  getCoverImage,
  getDescription,
  getGallery,
  getId,
  getMagentoId,
  getName,
  getPrice,
  getProductRelatedProduct,
  getProductSku,
  getProductThumbnailImage,
  getProductUpsellProduct,
  getShortDescription,
  getSlug,
  getTotalReviews,
  getTypeId,
  getSwatchData,
  getGroupedProducts,
  getBundleProducts,
  isInStock,
  isInShowroomAvailable,
  isOrderMoreAvailable,
  getTotalStoreAvailability,
  getAvailableQuantity,
  getAvailabilityInShowroom
}

// eslint-disable-next-line import/no-default-export
export default productGetters
