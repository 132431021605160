import { computed, ComputedGetter, ComputedRef } from '@nuxtjs/composition-api'

/**
 * https://github.com/nuxt-community/composition-api/blob/main/src/runtime/composables/fetch.ts#L230
 */

export interface SafeComputed<T> extends ComputedRef<T> {
  _compiled: boolean
}

export function safeComputed<T> (getter: ComputedGetter<T>): SafeComputed<T> {
  const cop: SafeComputed<T> = computed({
    set: (val) => {
      // eslint-disable-next-line no-console
      console.warn('Write operation on computed, payload:', val)
    },
    get: getter
  }) as any as SafeComputed<T>
  cop._compiled = true
  return cop
}
