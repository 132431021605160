import { CustomQuery, UseContextReturn } from '~/types/core'
import { AatriumAvailablePaymentMethod } from '~/modules/checkout/aatrium/types'

export const getAvailablePaymentMethodsCommand = {
  execute: async (context: UseContextReturn, cartId: string, customQuery?: CustomQuery): Promise<AatriumAvailablePaymentMethod[]> => {
    const { data } = await context.app.$vsf.$magento.api.getAvailablePaymentMethods({ cartId }, customQuery)

    return data?.cart?.available_payment_methods ?? []
  }
}
