import { defineStore } from 'pinia'
import { ref } from '@nuxtjs/composition-api'
import axios from 'axios'
import { KlevuRootSettings, UseKlevuSettingsStore } from '~/modules/klevu-search/store/types'
import { getContextEndpoint } from '~/modules/helpers/functions/getContextEndpoint'
import { safeComputed } from '~/modules/helpers'

export const useKlevuSettingsStore = defineStore('klevu', (): UseKlevuSettingsStore => {
  const rootSettings = ref<KlevuRootSettings>(null)

  async function loadRootSettings (locale: string, force?: boolean): Promise<void> {
    if (!force && rootSettings.value) {
      return
    }

    const { data }: { data: KlevuRootSettings } = await axios.get(getContextEndpoint(`/klevu-api/${locale}/settings`))
    rootSettings.value = data
  }

  const popularTerms = safeComputed<string[]>(() => rootSettings.value?.klevu_webstorePopularTerms ?? [])

  return {
    loadRootSettings,
    rootSettings,
    popularTerms
  }
})
