import { useContext } from '@nuxtjs/composition-api'
import { UseImageInterface } from './useImage'

const imageSizes = {
  productCard: {
    width: 216,
    height: 268
  },
  productCardHorizontal: {
    width: 140,
    height: 200
  },
  checkout: {
    imageWidth: 100,
    imageHeight: 100
  },
  productGallery: {
    thumbWidth: 160,
    thumbHeight: 160,
    imageWidth: 1080,
    imageHeight: 1340
  },
  cart: {
    imageWidth: 170,
    imageHeight: 170
  }
}

/**
 * Allows extracting image paths from magento URL.
 *
 * See the {@link UseImageInterface} for a list of methods and values available in this composable.
 * */
export function useImage (): UseImageInterface {
  const { $config } = useContext()
  /**
   * Extract image path from Magento URL.
   *
   * @param fullImageUrl {string}
   *
   * @return {string}
   */
  const getMagentoImage = (fullImageUrl: string): string => {
    const { imageProvider, magentoBaseUrl, imageProviderBaseUrl, strapiMediaUrl, imageProviderMagentoBaseUrl, montonioMediaUrl } = $config

    if (imageProvider !== 'ipx') {
      const url = fullImageUrl.split(`${magentoBaseUrl as string}`).pop()
      const regex = /cache\/(.*?)\//gm
      return url.replace(regex, '')
    }

    if (fullImageUrl) {
      Array<string>(imageProviderBaseUrl, strapiMediaUrl, imageProviderMagentoBaseUrl, montonioMediaUrl).forEach((url) => {
        if (fullImageUrl.includes(url)) {
          fullImageUrl = fullImageUrl.replace(url, '')
        }
      })
    }

    return fullImageUrl
  }

  return {
    getMagentoImage,
    imageSizes
  }
}

export * from './useImage'
// eslint-disable-next-line import/no-default-export
export default useImage
