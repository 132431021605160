<template functional>
  <transition :name="props.transition">
    <div
      v-if="props.visible"
      ref="overlay"
      :class="[data.class, data.staticClass, 'overlay']"
      @click="listeners.click && listeners.click($event)"
    ></div>
  </transition>
</template>

<script>
export default {
  name: "Overlay",
  props: {
    transition: {
      type: String,
      default: "fade",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--overlay-z-index, 1);
  background: var(--overlay-background, rgba(0,0,0, 0.2));
}
</style>
