import { computed, readonly, ref, useContext } from '@nuxtjs/composition-api'
import { findItemOnWishlist } from '~/modules/wishlist/helpers/findItemOnWishlist'
import { useMagentoConfiguration, useUiNotification } from '~/composables'
import { useI18n } from '~/helpers/hooks/usei18n'
import { useApi } from '~/composables/useApi'
import { Logger } from '~/helpers/logger'
import { useCartStore } from '~/modules/checkout/stores/cart'
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore'
import type { Wishlist } from '~/modules/GraphQL/types'
import type {
  UseWishlistAddItemParams,
  UseWishlistErrors,
  UseWishlistInterface,
  UseWishlistLoadParams,
  UseWishlistRemoveItemParams,
  UseWishlistAfterAddingWishlistItemToCartParams
} from '~/modules/wishlist/composables/useWishlist/useWishlist'
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand'
import { addWishlistItemsToCartMutation } from '~/modules/wishlist/aatrium/graphql/addWishlistItemsToCartMutation'
import { GtagProductActions, GtagProductAnalyticsData, sendGtagProductEvent } from '~/modules/aatrium-gtm'
import { preparedGtagItems } from '~/modules/aatrium-gtm/helpers'
import { Product } from '~/modules/catalog/product/types'
import { getTotals } from '~/modules/wishlist/getters/wishlistGetters'
import { getItems, getTotals as getTotalsCart } from '~/modules/checkout/getters/cartGetters'

/**
 * Allows loading and manipulating wishlist of the current user.
 *
 * See the {@link UseWishlistInterface} for a list of methods and values available in this composable.
 */
export function useWishlist (): UseWishlistInterface {
  const wishlistStore = useWishlistStore()
  const cartStore = useCartStore()
  const { app } = useContext()
  const i18n = useI18n()
  const { query } = useApi()
  const { send: sendNotification } = useUiNotification()
  const { selectedCurrency } = useMagentoConfiguration()
  const loading = ref(false)

  function calculateWishlistTotal (wishlists: Wishlist[]): number {
    return wishlists.reduce((acc, current) => acc + (current?.items_count ?? 0), 0)
  }

  const error = ref<UseWishlistErrors>({
    addItem: null,
    removeItem: null,
    load: null,
    clear: null,
    loadItemsCount: null,
    afterAddingWishlistItemToCart: null,
    addAllItemsToCart: null
  })

  async function load (params?: UseWishlistLoadParams): Promise<Wishlist> {
    Logger.debug('useWishlist/load')

    try {
      loading.value = true
      Logger.debug('[Magento Storefront]: useWishlist.load params->', params)
      const apiState = app.$vsf.$magento.config.state

      if (apiState.getCustomerToken()) {
        const { data } = await app.$vsf.$magento.api.wishlist(params?.searchParams, params?.customQuery ?? {
          wishlist: 'wishlist'
        })

        Logger.debug('[Result]:', { data })
        const loadedWishlist = data?.customer?.wishlists ?? []
        if (loadedWishlist[0]) {
          // @ts-expect-error M2-579
          [wishlistStore.wishlist] = loadedWishlist
        }
      }

      error.value.load = null
    } catch (err) {
      error.value.load = err
      Logger.error('useWishlist/load', err)
    } finally {
      loading.value = false
    }

    return wishlistStore.wishlist
  }

  function isInWishlist ({ product }: {product: { sku?: string, uid?: string } }): boolean {
    Logger.debug('useWishlist/isInWishlist', product)

    if (!product) {
      return false
    }

    const wishlistProduct = findItemOnWishlist(wishlistStore.wishlist, product)

    return !!(wishlistProduct?.id && wishlistProduct?.quantity)
  }

  function setWishlist (newWishlist: Wishlist): void {
    wishlistStore.wishlist = newWishlist
    Logger.debug('useWishlist/setWishlist', newWishlist)
  }

  async function removeItem ({ product, customQuery }: UseWishlistRemoveItemParams): Promise<void> {
    Logger.debug('useWishlist/removeItem', product)

    try {
      loading.value = true
      Logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
        customQuery
      })

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product)
      const { data } = await app.context.$vsf.$magento.api.removeProductsFromWishlist({
        id: '0',
        items: [itemOnWishlist.id]
      }, customQuery ?? {
        removeProductsFromWishlist: 'removeProductsFromWishlist'
      })

      Logger.debug('[Result]:', { data })
      error.value.removeItem = null
      wishlistStore.$patch((state) => {
        state.wishlist = data?.removeProductsFromWishlist?.wishlist ?? {}
      })

      sendNotification({
        id: Symbol('remove-from-wishlist'),
        type: 'success',
        icon: 'remove-from-wishlist',
        title: i18n.t('Product removed from wishlist'),
        persist: false,
        timeToLive: 5000
      })
    } catch (err) {
      error.value.removeItem = err
      Logger.error('useWishlist/removeItem', err)
    } finally {
      loading.value = false
    }
  }

  async function loadItemsCount (): Promise<number | null> {
    Logger.debug('useWishlist/wishlistItemsCount')
    const apiState = app.context.$vsf.$magento.config.state
    let itemsCount: number | null = null

    try {
      loading.value = true
      error.value.loadItemsCount = null
      if (apiState.getCustomerToken()) {
        const { data } = await app.context.$vsf.$magento.api.wishlistItemsCount()

        Logger.debug('[Result]:', { data })
        const loadedWishlist: Wishlist[] = data?.customer?.wishlists ?? []
        itemsCount = calculateWishlistTotal(loadedWishlist)
        wishlistStore.$patch((state) => {
          state.wishlist.items_count = itemsCount
        })
      }
    } catch (err) {
      error.value.loadItemsCount = err
      Logger.error('useWishlist/wishlistItemsCount', err)
    } finally {
      loading.value = false
    }

    return itemsCount
  }

  async function addItem ({ product, customQuery }: UseWishlistAddItemParams, analyticData?: GtagProductAnalyticsData): Promise<void> {
    Logger.debug('useWishlist/addItem', product)

    try {
      loading.value = true
      Logger.debug('[Magento Storefront]: useWishlist.addItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
        customQuery
      })

      if (!wishlistStore.wishlist) {
        await load({})
      }

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product)

      if (itemOnWishlist) {
        return
      }

      if (!app.$vsf.$magento.config.state.getCustomerToken()) {
        Logger.error('Need to be authenticated to add a product to wishlist')
      }

      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCardProduct':
        case 'SimpleProduct': {
          const { data } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.sku,
              quantity: 1
            }]
          }, customQuery ?? {
            addProductsToWishlist: 'addProductsToWishlist'
          })

          Logger.debug('[Result]:', { data })

          wishlistStore.$patch((state) => {
            state.wishlist = data?.addProductsToWishlist?.wishlist ?? {}
          })

          sendNotification({
            id: Symbol('added-to-wishlist'),
            type: 'success',
            icon: 'added-to-wishlist',
            title: i18n.t('Product added to wishlist'),
            persist: false,
            timeToLive: 5000
          })

          break
        }
        case 'ConfigurableProduct': {
          const { data: configurableProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.configurable_product_options_selection?.variant?.sku || product.sku,
              quantity: 1,
              parent_sku: product.sku
            }]
          }, customQuery ?? {
            addProductsToWishlist: 'addProductsToWishlist'
          })

          Logger.debug('[Result]:', { data: configurableProductData })

          wishlistStore.$patch((state) => {
            state.wishlist = configurableProductData?.addProductsToWishlist?.wishlist ?? {}
          })

          break
        }
        case 'BundleProduct': {
          const { data: bundleProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.sku,
              quantity: 1,
              entered_options: []
            }]
          }, customQuery ?? {
            addProductsToWishlist: 'addProductsToWishlist'
          })

          Logger.debug('[Result]:', { data: bundleProductData })

          wishlistStore.$patch((state) => {
            state.wishlist = bundleProductData?.addProductsToWishlist?.wishlist ?? {}
          })

          break
        }
        default:
          Logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`)
      }

      sendGtagProductEvent(GtagProductActions.AddToWishlist, {
        currency: selectedCurrency.value,
        value: getTotals(wishlistStore.wishlist).total,
        items: preparedGtagItems([{
          product,
          quantity: 1,
          index: analyticData?.index
        }])
      })

      sendNotification({
        id: Symbol('added-to-wishlist'),
        type: 'success',
        icon: 'added-to-wishlist',
        title: i18n.t('Product added to wishlist'),
        persist: false,
        timeToLive: 5000
      })
    } catch (err) {
      error.value.addItem = err
      Logger.error('useWishlist/addItem', err)
    } finally {
      loading.value = false
    }
  }

  async function clear (): Promise<void> {
    Logger.debug('useWishlist/clear')

    try {
      loading.value = true
      error.value.clear = null
      await wishlistStore.$patch((state) => {
        state.wishlist = {}
      })
    } catch (err) {
      error.value.clear = err
      Logger.error('useWishlist/clear', err)
    } finally {
      loading.value = false
    }
  }

  async function afterAddingWishlistItemToCart ({ product, cartError }: UseWishlistAfterAddingWishlistItemToCartParams): Promise<void> {
    Logger.debug('useWishlist/afterAddingItemToCart', product)

    if (!isInWishlist({ product })) return

    try {
      if (cartError?.message) {
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist_error'),
          message: cartError.message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'Wishlist error'
        })
      } else {
        // eslint-disable-next-line promise/catch-or-return
        await removeItem({ product })
          // eslint-disable-next-line promise/always-return
          .then(() => {
            sendNotification({
              id: Symbol('product_added_to_cart_from_wishlist'),
              message: app.i18n.t(
                'You added {product} to your shopping cart.',
                { product: product.name }
              ) as string,
              type: 'success',
              icon: 'check',
              persist: false,
              title: 'Wishlist'
            })
          })
      }
    } catch (err) {
      error.value.afterAddingWishlistItemToCart = err
      Logger.error('useWishlist/afterAddingWishlistItemToCart', err)
    }
  }

  async function addOrRemoveItem ({ product, customQuery }: UseWishlistAddItemParams): Promise<void> {
    await (isInWishlist({ product })
      ? await removeItem({ product, customQuery })
      : await addItem({ product, customQuery }))
  }

  async function addAllItemsToCart (): Promise<void> {
    Logger.debug('useWishlist/addAllItemsToCart')
    loading.value = true

    try {
      const context = app.$vsf
      const wishlistId = wishlistStore?.wishlist.id
      const wishlistItemIds = wishlistStore?.wishlist.items_v2.items.map(i => i.id)

      const { data } = await query<{ addWishlistItemsToCart: { wishlist: Wishlist | null, status: boolean } }>(
        addWishlistItemsToCartMutation, { wishlistId, wishlistItemIds }
      )

      if (data?.addWishlistItemsToCart.status) {
        const loadedCart = await loadCartCommand.execute(context, { customQuery: { cart: 'cart' } })

        cartStore.$patch((state) => {
          state.cart = loadedCart
        })

        wishlistStore.$patch((state) => {
          state.wishlist = data?.addWishlistItemsToCart?.wishlist ?? {}
        })

        sendGtagProductEvent(GtagProductActions.AddToCart, {
          currency: selectedCurrency.value,
          value: getTotalsCart(cartStore.cart).total,
          items: preparedGtagItems(getItems(cartStore.cart).map(product => ({
            product: product.product as Product,
            quantity: product.quantity
          })))
        })

        sendNotification({
          id: Symbol('added-to-cart'),
          type: 'success',
          icon: 'added-to-cart',
          title: i18n.t('Products added to cart'),
          persist: false,
          timeToLive: 5000
        })
      }

      Logger.debug('[Result]:', { data })
      error.value.addAllItemsToCart = null
    } catch (err) {
      error.value.addAllItemsToCart = err
      Logger.error('useWishlist/addAllItemsToCart', err)
    } finally {
      loading.value = false
    }
  }

  return {
    wishlist: computed(() => wishlistStore.wishlist),
    loadItemsCount,
    isInWishlist,
    addItem,
    load,
    removeItem,
    clear,
    setWishlist,
    afterAddingWishlistItemToCart,
    addOrRemoveItem,
    addAllItemsToCart,
    loading: readonly(loading),
    error: readonly(error)
  }
}

export * from './useWishlist'
