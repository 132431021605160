import { AuthSidebarModeWithTitle } from "~/composables/useUiState/interfaces/AuthSidebarModeWithTitle";
import { AuthSidebarMode } from "~/composables/useUiState/enums/AuthSidebarMode";

export const AuthSidebarModes: AuthSidebarModeWithTitle[] = [
  {
    text: 'Login',
    value: AuthSidebarMode.login
  },
  {
    text: 'Register',
    value: AuthSidebarMode.preRegister
  }
];
