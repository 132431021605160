import { Wishlist, WishlistItemInterface } from '~/modules/GraphQL/types'
import { productMatch } from '~/modules/wishlist/helpers/productMatch'

export function findItemOnWishlist (currentWishlist: Wishlist, product: {sku?: string, uid?: string}): WishlistItemInterface | undefined {
  const wishlist: Wishlist = Array.isArray(currentWishlist) ? currentWishlist[0] : currentWishlist

  return wishlist
    ?.items_v2
    ?.items?.find(item => productMatch(item.product, product))
}
