
import type { PropType } from '@nuxtjs/composition-api'
import { defineComponent } from '@nuxtjs/composition-api'
import type { NuxtError } from '@nuxt/types'
import Error from '~/components/Error/Error.vue'

export default defineComponent({
  name: 'ErrorLayout',
  layout: 'minimal',
  components: {
    Error
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true
    }
  }
})
