
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'HeaderLogo',
  setup () {
    const generalAttributes = {
      alt: 'logo',
      fit: 'cover',
      format: 'webp',
      loading: 'eager',
      preload: {
        fetchPriority: 'high'
      }
    }

    return {
      generalAttributes
    }
  }
})
