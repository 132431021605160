import { Plugin } from '@nuxt/types'
import { onGlobalSetup } from '@nuxtjs/composition-api'
import { useLastViewedProductsStore } from '~/modules/last-viewed-products/store/lastViewedProductsStore'

// eslint-disable-next-line func-style
const lastViewedProductsPlugin: Plugin = (app) => {
  onGlobalSetup(async () => {
    const lastViewedProductsStore = useLastViewedProductsStore(app.$pinia)

    if (!process.server) {
      await lastViewedProductsStore.load()
    }
  })
}

// eslint-disable-next-line import/no-default-export
export default lastViewedProductsPlugin
