import { computed, reactive, useContext, watch } from '@nuxtjs/composition-api'
import { CustomerCreateInput } from '@vue-storefront/magento-api/lib/types/GraphQL'
import { FetchResult } from '@apollo/client/core'
import { AuthSidebarMode } from '../useUiState/enums/AuthSidebarMode'
import { EstAuthError } from './authError'
import { authMethodFactory } from './authMethodFactory'
import {
  createCompany as createCompanyMutation,
  generateCustomerToken as generateCustomerTokenMutation,
  generateCustomerTokenAsync as generateCustomerTokenAsyncMutation,
  retrieveAsyncAuthRequestStatus
} from './graphql'
import {
  EstAuthCodeInput,
  EstAuthCustomerType,
  EstAuthGenerateCustomerTokenInput,
  EstAuthMethod,
  EstAuthMethods,
  EstAuthState,
  UseEstAuth,
  EstAuthSetNewPasswordInput
} from './types'
import { EstAuthStateInterface } from './interfaces/EstAuthStateInterface'
import { STATUSES } from '~/enums/statusesEnum'
import { generateCustomerTokenGetters } from '~/composables/useEstAuth/getters'
import { useI18n } from '~/helpers/hooks/usei18n'
import { useUser } from '~/modules/customer/composables/useUser'
import { useWishlist } from '~/modules/wishlist/composables/useWishlist'
import { useUiState } from '~/composables'
import { VsfContext } from '~/composables/context'
import { generateUserData } from '~/modules/customer/helpers/generateUserData'

const state = reactive<EstAuthStateInterface>({
  method: EstAuthMethod.MobileId,
  token: null,
  loading: false,
  result: {
    generateCode: null,
    generateCustomerToken: null,
    register: null
  },
  error: {
    generateCode: null,
    generateCustomerToken: null,
    login: null,
    register: null,
    resetPassword: null
  }
})

export function useEstAuth (): UseEstAuth {
  const { app } = useContext()
  const i18n = useI18n()
  const { authSidebarMode } = useUiState()
  const context: VsfContext = app.$vsf
  const { load: loadUser, mergeCart, loginByToken } = useUser()
  const { load: loadWishlist } = useWishlist()

  watch(() => state.method, () => {
    state.result.generateCode = null
    state.error = { register: null, generateCustomerToken: null, login: null, generateCode: null, resetPassword: null }
  })

  const methods = computed<EstAuthMethods[]>(() => {
    const result = [
      {
        text: i18n.t('Mobile-ID'),
        value: EstAuthMethod.MobileId
      },
      {
        text: i18n.t('Smart-ID'),
        value: EstAuthMethod.SmartId
      },
      {
        text: i18n.t('Email'),
        value: EstAuthMethod.Email
      }
    ]

    if (app.$device.isDesktop) {
      result.push({
        text: i18n.t('Card-ID'),
        value: EstAuthMethod.CardId
      })
    }

    return result
  })

  const isSelectedSimpleAuthMethod = computed<boolean>(() => {
    return ([EstAuthMethod.Email] as EstAuthMethod[]).includes(state.method)
  })

  function resetErrors (): void {
    state.error.generateCode = null
    state.error.generateCustomerToken = null
    state.error.login = null
    state.error.register = null
  }

  function setMethod (value: EstAuthMethod): void {
    resetErrors()
    state.method = value
  }

  async function generateCode (input: EstAuthCodeInput, mode: AuthSidebarMode = authSidebarMode.value): Promise<EstAuthState> {
    try {
      const authMethod = authMethodFactory(
        context,
        state.method,
        mode,
        input.customerType || EstAuthCustomerType.Personal
      )

      state.loading = true
      state.result.generateCode = await authMethod.generateCode(input, mode)
      state.error.generateCode = null

      return EstAuthState.CodeReceived
    } catch (err) {
      state.result.generateCode = null
      state.error.generateCode = err

      return EstAuthState.Failed
    } finally {
      state.loading = false
    }
  }

  async function getAsyncAuthRequestStatus (requestId: string, requestSecret: string): Promise<{ authResponse, status, error? }> {
    return await new Promise((resolve, reject) => {
      setTimeout(async function retrieveTimeout () {
        try {
          const { data: retrieveData, errors } = await context.$magento.api.customMutation({
            mutation: retrieveAsyncAuthRequestStatus as any,
            mutationVariables: {
              request_id: requestId,
              request_secret: requestSecret
            }
          }) as FetchResult<any>

          if (errors) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject({
              error: new EstAuthError('getAsyncAuthRequestStatusTitle', 'getAsyncAuthRequestStatusMessage')
            })
          }

          const { auth_response: authResponse, status } = retrieveData?.retrieveAsyncAuthRequestStatus

          if (![STATUSES.PENDING, STATUSES.SUCCESS].includes(status)) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject({
              error: new EstAuthError()
            })
          }

          if (status === STATUSES.PENDING) {
            return setTimeout(retrieveTimeout, 1000)
          }

          resolve({
            authResponse,
            status
          })
        } catch {
          return setTimeout(retrieveTimeout, 1000)
        }
      }, 1000)
    })
  }

  function getEstAuthState (params: EstAuthGenerateCustomerTokenInput, estAuthGenerateCustomerToken): EstAuthState {
    if (generateCustomerTokenGetters.getToken(estAuthGenerateCustomerToken)) {
      state.token = generateCustomerTokenGetters.getToken(estAuthGenerateCustomerToken)
      state.result.generateCustomerToken = estAuthGenerateCustomerToken
      state.error.generateCustomerToken = null

      return EstAuthState.Exists
    } else if (params.customerType === EstAuthCustomerType.Personal && generateCustomerTokenGetters.getPersonalAccount(estAuthGenerateCustomerToken)) {
      state.token = generateCustomerTokenGetters.getPersonalAccount(estAuthGenerateCustomerToken).token
      state.result.generateCustomerToken = estAuthGenerateCustomerToken
      state.error.generateCustomerToken = null

      return EstAuthState.Exists
    } else if (params.customerType === EstAuthCustomerType.Business && generateCustomerTokenGetters.getBusinessAccount(estAuthGenerateCustomerToken, params.email)) {
      state.token = generateCustomerTokenGetters.getBusinessAccount(estAuthGenerateCustomerToken, params.email).token
      state.result.generateCustomerToken = estAuthGenerateCustomerToken
      state.error.generateCustomerToken = null

      return EstAuthState.Exists
    }

    state.token = null
    state.result.generateCustomerToken = estAuthGenerateCustomerToken
    state.error.generateCustomerToken = new EstAuthError('generateCustomerToken')

    return EstAuthState.NotRegistered
  }

  async function generateCustomerToken (params: EstAuthGenerateCustomerTokenInput, isMobile: boolean): Promise<EstAuthState> {
    try {
      state.loading = true
      params.identity = params.identityNumber // hack to support mobile-id and smart-id methods

      const { data, errors } = await context.$magento.api.customMutation({
        mutation: (isMobile ? generateCustomerTokenAsyncMutation : generateCustomerTokenMutation) as any,
        mutationVariables: {
          method: state.method,
          data: Object.entries(params).map(([key, value]) => ({ key, value }))
        }
      }) as FetchResult<any>

      if (errors) {
        state.token = null
        state.result.generateCustomerToken = null
        state.error.generateCustomerToken = new EstAuthError(
          'generateCustomerTokenError',
          errors[0]?.message || 'generateCustomerTokenMessage'
        )

        return EstAuthState.Failed
      }

      if (isMobile) {
        const { request_id: requestId, request_secret: requestSecret } = data.estAuthGenerateCustomerTokenAsync
        const { authResponse, error: retrieveError } = await getAsyncAuthRequestStatus(requestId, requestSecret)

        if (retrieveError) {
          state.token = null
          state.result.generateCustomerToken = null
          state.error.generateCustomerToken = retrieveError

          return EstAuthState.Failed
        }

        return getEstAuthState(params, authResponse)
      }

      return getEstAuthState(params, data.estAuthGenerateCustomerToken)
    } catch (err) {
      console.error(err)
      state.token = null
      state.result.generateCustomerToken = null
      state.error.generateCustomerToken = new EstAuthError()

      return EstAuthState.Failed
    } finally {
      state.loading = false
    }
  }

  async function login (token: string, mergeCarts: boolean = true): Promise<EstAuthState> {
    try {
      state.loading = true
      await loginByToken(token)
      await loadUser()
      await loadWishlist()

      if (mergeCarts) {
        await mergeCart()
      }
      return EstAuthState.Authenticated
    } catch (err) {
      console.error(err)
      state.error.login = new EstAuthError('login', err.message)
      return EstAuthState.Failed
    } finally {
      state.loading = false
    }
  }

  async function createCompany (input): Promise<any> {
    const { data, errors } = (await context.$magento.api.customMutation({
      mutation: createCompanyMutation(input.country_id || 'EE'),
      mutationVariables: {
        ...input
      }
    })) as FetchResult<any>

    if (!data.createCompany?.company || !data.createCompany?.token || errors) {
      const message = errors ? errors.map(e => e.message).join(',') : 'Company creation error'

      throw new EstAuthError('createCompany', message)
    }

    return data.createCompany
  }

  async function createCustomer (input: CustomerCreateInput): Promise<EstAuthState> {
    let complectedRegisterData: CustomerCreateInput

    try {
      state.result.register = null
      complectedRegisterData = {
        // method: state.method,
        password: (Math.random() + 1).toString(36).substring(2) + 'Zap6!',
        ...input
      }

      const { data, errors } = await context.$magento.api.createCustomer(
        generateUserData(complectedRegisterData)
      )

      if (errors || !data?.createCustomerV2?.customer) {
        const message = errors ? errors.map(e => e.message).join(',') : 'Customer registration error'
        throw new EstAuthError('createCustomer', message)
      }

      state.result.register = data.createCustomerV2?.customer
    } catch (err) {
      console.error(err)
      state.error.register = err
      return EstAuthState.Failed
    }

    return await loginViaEmailPassword(complectedRegisterData.email, complectedRegisterData.password)
  }

  async function loginViaEmailPassword (email: string, password: string, recaptchaToken?: string): Promise<EstAuthState> {
    try {
      state.loading = true
      const { data, errors } = await context.$magento.api.generateCustomerToken(
        { email, password, recaptchaToken }, {}
      )

      if (errors || !data?.generateCustomerToken?.token) {
        throw new EstAuthError(
          'Login error',
          errors[0]?.message || 'Email or password is not correct, check your input and try again'
        )
      }
      state.token = data?.generateCustomerToken?.token
      return await login(data?.generateCustomerToken?.token)
    } catch (err) {
      console.error(err)
      state.error.login = err
    } finally {
      state.loading = false
    }
  }

  async function register (customerType: EstAuthCustomerType, input: any): Promise<EstAuthState> {
    try {
      state.loading = true

      return (customerType === EstAuthCustomerType.Business)
        ? await createCompany(input)
        : await createCustomer(input)
    } catch (err) {
      state.token = null
      state.error.register = err

      return EstAuthState.Failed
    } finally {
      state.loading = false
    }
  }

  function resetCode (): void {
    state.result.generateCode = null
  }

  async function resetPassword (email: string, recaptchaToken?: string): Promise<boolean> {
    try {
      state.loading = true
      const { data } = await context.$magento.api.requestPasswordResetEmail(
        { email, recaptchaToken }, {}
      )

      if (!data?.requestPasswordResetEmail) {
        throw new EstAuthError(
          'Reset password',
          'E-mail address is incorrect'
        )
      } else {
        state.error.resetPassword = null
      }

      return data?.requestPasswordResetEmail || false
    } catch (err) {
      console.error(err)
      state.error.resetPassword = err
    } finally {
      state.loading = false
    }
  }

  async function setNewPassword (params: EstAuthSetNewPasswordInput): Promise<boolean> {
    const { email, password, token, recaptchaToken } = params
    try {
      state.loading = true
      const { data } = await context.$magento.api.resetPassword({
        email,
        newPassword: password,
        resetPasswordToken: token,
        recaptchaToken
      }, {})

      if (!data?.resetPassword) {
        throw new EstAuthError(
          'Reset password',
          'Email or password is not correct, check your input and try again'
        )
      } else {
        state.error.resetPassword = null
      }

      return data?.resetPassword || false
    } catch (err) {
      console.error(err)
      state.error.resetPassword = err
    } finally {
      state.loading = false
    }
  }

  return {
    setMethod,
    generateCode,
    generateCustomerToken,
    loginViaEmailPassword,
    login,
    register,
    resetCode,
    resetPassword,
    setNewPassword,
    methods,
    isSelectedSimpleAuthMethod,
    method: computed(() => state.method),
    token: computed(() => state.token),
    loading: computed(() => state.loading),
    result: computed(() => state.result),
    error: computed(() => state.error)
  }
}
