/* eslint-disable */
export default {
	" /item": " /toode",
	"A customer with the same email address already exists in an associated website.": "Sellise e-posti aadressiga klient on juba registreeritud",
	"A website outside of the EU is required to comply with the GDPR if it collects data from users inside the EU.": "A website outside of the EU is required to comply with the GDPR if it collects data from users inside the EU.",
	"AATRIUM_HOME_DELIVERY_INFO": "Koju kätte tarnega on võimalik juurde valida vana mööbli utiliseerimise ja/või uue mööbli kokkupanemise teenus.",
	"AATRIUM_HOME_DELIVERY_TITLE": "Koju kätte tarne",
	"Accept GDPR agreement": "Nõustu GDPR tingimustega",
	"Acceptance date:": "Vastuvõtupäev:",
	"ACCEPTANCE_DETAILS_PAGE_TITLE": "GDPR nõustumised",
	"Accepts only alphanumeric characters": "Aktsepteerib ainult tähtnumbrilisi sümboleid",
	"Add all to cart": "Lisa kõik tooted ostukovi",
	"Add furniture to assemble": "Lisa toote kokkupanek",
	"Add item": "Lisa ese",
	"Add to cart": "Lisa ostukorvi",
	"Additional information": "Lisateave",
	"all": "küik",
	"Allow all": "Luba küpsised",
	"Amount": "Kasutatav summa",
	"Amount left": "Vaba jääk",
	"Amount of the shopping cart:": "Ostukorvi summa:",
	"Applied filter": "Aktiivne filter",
	"Applied filters": "Aktiivset filtrit",
	"Apply": "Rakenda",
	"Apply gift card": "Kasuta kinkekaarti",
	"April": "Aprill",
	"Are you sure you want to cancel GDPR agreement?": "Kas olete kindel, et soovite GDPR lepingu tühistada?",
	"Are you sure you want to delete your account?": "Kas oled kindel, et soovid oma konto kustutada?",
	"Are you sure you want to remove your default address?": "Kas olete kindel, et soovite oma vaikimisi aadressi eemaldada?",
	"August": "August",
	"Authentication failed": "Sisselogimine ebaõnnestus.",
	"Available in the showroom": "Müügisaalis näidis",
	"Back to orders list": "Tagasi tellimuste nimekirja",
	"Back to shopping": "Tagasi ostlema",
	"Backorder": "Tellimisel",
	"Bank link payment": "Pangalink",
	"Bed": "Voodi",
	"Bed side table": "Öökapp / Sahtliboks",
	"Billing address": "Arveldus aadress",
	"Billing address is the same as delivery address": "Arveldusaadress on sama kui tarneaadress",
	"blog": "Uudised",
	"Campaign": "Kampaania",
	"Campaign -40%": "Kampaania -40%",
	"Campaigns": "Kampaaniad",
	"Can order more": "Tellides rohkem",
	"Cancel": "Tühista",
	"Cancel GDPR agreement": "Tühistada GDPR leping",
	"Cancel GDPR agreement?": "Tühistada GDPR leping?",
	"Canceled": "Tühistatud",
	"Card code": "Kaardi number",
	"Card-ID": "ID-kaart",
	"Cart": "Ostukorv",
	"Cart has been cleared": "Ostukorv on tühjendatud",
	"Cart has been updated": "Ostukorv on uuendatud",
	"Categories": "Kategooria",
	"Categories page": "Kategooriad",
	"category": "kategoria",
	"Category": "KATEGOORIA",
	"Chair": "Söögitoatool / kontoritool",
	"Change": "Muuda",
	"Change Contact info": "Muuda kontaktandmeid",
	"Change method": "Muuda meetodit",
	"Change Payment": "Muuda makseviisi",
	"Change Shipping": "Vali teine tarnemeetod",
	"Check balance": "Kontrolli jääki",
	"Check if you entered a correct email": "Kontrolli, kas sisestatud e-mail on õige",
	"Check your personal identification number": "Kontrollige oma isikukoodi",
	"Checkout": "Vormista ost",
	"CHECKOUT_PAGE_TITLE": "Ostu vormistamine",
	"Chest of drawers 1": "Kummut 1-osaline",
	"Chest of drawers 2": "Kummut 2-osaline / TV laud",
	"Choose a gift card type": "Vali kinkekaardi tüüp",
	"Closet 1 door": "Kapp 1 uksega",
	"Closet 2 door": "Kapp 2 uksega",
	"Closet 3 door": "Kapp 3 uksega",
	"Closet 4 door": "Kapp 4 uksega",
	"colors": "värv",
	"Colors": "VÄRV",
	"Company": "Ettevõte",
	"Company name": "Ettevõtte nimi",
	"Completed": "Valmis",
	"Confirm new password": "Kinnitage uus parool",
	"Confirmation has been sent to your e-mail.": "Teie e-mailile on saadetud kinnitus liitumise kohta.",
	"Congratulations!": "Oih!",
	"Consent must be freely given, i.e. not allowed to be forced.": "Consent must be freely given, i.e. not allowed to be forced.",
	"Consent must be renewed at least once per year. However, some national data protection guidelines recommend more frequent renewal, e.g. 6 months. Check your local data protection guidelines for compliance.": "Consent must be renewed at least once per year. However, some national data protection guidelines recommend more frequent renewal, e.g. 6 months. Check your local data protection guidelines for compliance.",
	"Consents must be as easily withdrawn as they are given.": "Consents must be as easily withdrawn as they are given.",
	"Consents must be granular, i.e. users must be able to activate some cookies rather than others and not be forced to consent to either all or none.": "Consents must be granular, i.e. users must be able to activate some cookies rather than others and not be forced to consent to either all or none.",
	"Consents must be securely stored as legal documentation.": "Consents must be securely stored as legal documentation.",
	"Contact info": "Kontaktinfo",
	"Contacts": "Kontaktid",
	"Continental bed": "Kontinentaalvoodi",
	"Continue": "Jätka",
	"Continue shopping": "Jätka ostlemist",
	"Coupon code is not valid": "Kupongi kood ei kehti",
	"Creating an account": "Registreeri kliendiks",
	"Current password": "Praegune parool",
	"Customer GDPR request": "Kliendi GDPR päring",
	"Customer or personal code": "Kliendinumber või isikukood",
	"Date": "Kuupäev",
	"December": "Detsember",
	"Delete account?": "Kustuta konto?",
	"Delete my account": "Kustuta konto",
	"Delivery": "Transport",
	"delivery 1-2 weeks": "tarne 1-2 nädalat",
	"Delivery address": "Tarne aadress",
	"Delivery time: {text}": "Tellimisel: {text}",
	"Deposit": "Sissemakse",
	"depth": "Sügavus",
	"Depth": "Sügavus",
	"Details about GDPR agreement": "GDPRi lepingu üksikasjad",
	"Didn't receive an email?": "Ei saanud e-maili?",
	"dimensions": "mõõdud",
	"dimentsions": "mõõtmed",
	"Dining table": "Söögilaud",
	"Discount": "Hinnavõit",
	"Discount code": "Sooduskood",
	"Do not have an account?": "Kontot ei ole?",
	"Download Invoice": "Lae arve alla",
	"E-gift card": "E-kinkekaart",
	"E-mail address": "E-posti aadress",
	"E-mail address is incorrect": "Sisestatud e-post aadress ei ole korrektne",
	"E-store support": "E-poe klienditeenindus",
	"Edit": "Muuda",
	"Email": "E-post",
	"Email or password is not correct, check your input and try again": "Sisestatud e-post aadress ei ole korrektne, kontrollige oma sisendit ja proovige uuesti",
	"Enter current password": "Sisestage praegune parool",
	"Enter customer or personal code": "Sisesta kliendinumber või isikukood",
	"Enter discount code": "Sisestage allahindluskood",
	"Enter e-mail address": "Sisesta e-posti aadress",
	"Enter email": "Sisestage saaja e-posti aadress",
	"Enter from": "Sisestage kellelt",
	"Enter message": "Sisestage sõnum",
	"Enter new password": "Sisestage uus parool",
	"Enter to": "Sisestage kellele",
	"Enter what should be changed?": "Sisesta mida me peaksime muutma?",
	"Enter your email": "Sisesta e-posti aadress",
	"Enter your first name": "Sisesta eesnimi",
	"Enter your firstname": "Sisesta eesnimi",
	"Enter your identification code": "Sisestage oma isikukood",
	"Enter your identification number": "Sisesta isikukood",
	"Enter your last name": "Sisesta perekonnanimi",
	"Enter your lastname": "Sisesta perekonnanimi",
	"Enter your mobile number": "Sisestage oma mobiilinumber",
	"Enter your new password": "Uus parool",
	"Enter your password": "Sisesta parool",
	"Enter your phone number": "Sisesta telefoninumber",
	"ESTORE_CUSTOMER_SERVICE_HOURS": "E-R 9-17",
	"Even though cookies are mentioned only once in the GDPR, cookie consent is nonetheless a cornerstone of compliance for websites with EU-located users.": "Even though cookies are mentioned only once in the GDPR, cookie consent is nonetheless a cornerstone of compliance for websites with EU-located users.",
	"Everything is correct?": "Kõik on korrektne?",
	"February": "Veebruar",
	"Fill in the fields and confirm the data": "Palun täida kõik väljad",
	"Filling material": "Madratsi tüüp",
	"filters": "filtrid",
	"Filters": "Filtrid",
	"First name": "Eesnimi",
	"Firstname": "Eesnimi",
	"For your better service, we use cookies. To continue using this site, you will agree to use cookies.": "Et teid paremini teenindada, siis kasutame küpsiseid. Meie veebilehte külastades nõustute meiepoolse küpsiste kasutamisega.",
	"Forgot password?": "Unustasid parooli?",
	"Free": "Tasuta",
	"from": "alates",
	"From": "Alates",
	"From who": "Kellelt",
	"Furniture assembly": "Mööbli kokkupanek",
	"Furniture utilization": "Tellin mööbli utiliseerimise",
	"Gdpr acceptance details": "GDPR nõustumised",
	"GDPR acceptance details": "GDPR nõustumised",
	"GDPR Agreement": "GDPR leping",
	"GDPR_PAGE_DESCRIPTION": "Kliendi andmete GDPR päring",
	"GDPR_PAGE_TITLE": "Kliendi GDPR päring",
	"generateCustomerTokenError": "Sisselogimine ebaõnnestus.",
	"Get data": "Küsi andmeid",
	"Gift card applied": "Kinkekaart rakendatud",
	"Gift cards": "Kinkekaardid",
	"Go shopping": "Jätka ostlemist",
	"Go to home page": "Tagasi esilehele",
	"Grand total": "Lõppsumma",
	"Hang on!": "Palume kannatust!",
	"height": "Kõrgus",
	"Height": "Kõrgus",
	"Hello, Aatrium customer": "Kliendina sisse logimine",
	"Help plant forests": "Aita istutada metsi",
	"Hide all": "Peida kõik",
	"Home": "Avaleht",
	"HOME_PAGE_DESCRIPTION": "Alati lai valik mööblit ja sisustuskaupu. Aatrium on juba 20 aastat aidanud inimestel kodu sisustada mõistliku hinnaga. Soovime olla mitte lihtsalt mööblipood, vaid Sinu esimene valik kodu sisustamisel. Tule ja uuri, mida huvitavat pakub Eesti suurim sisustuskaubamaja ja meie e-pood!",
	"HOME_PAGE_TITLE": "Suurim mööblipood ja sisustuskaubamaja Eestis",
	"How to deal with the EU’s GDPR and cookie on your website": "How to deal with the EU’s GDPR and cookie on your website",
	"I agree": "Nõustun",
	"I agree to receive marketing messages": "Nõustun saama uudiskirju",
	"I consent to the processing of my personal data": "Nõustun isikuandmete töötlemisega",
	"I have read and accept the terms and conditions of sale": "Olen lugenud ja nõustun müügitingimustega",
	"ID card identification failed": "ID-kaardi tuvastamine ebaõnnestus",
	"Identification code": "Isikukood",
	"Identity number": "Isikukood",
	"In stock": "Laos",
	"In stock now": "Kohe laos",
	"Innovation sofa": "Innovation diivan",
	"Installment": "Järelmaks",
	"Installment calculator": "Järelmaksu kalkulaator",
	"Interest rate": "Intress",
	"Internal server error": "Palun proovi uuesti.",
	"Invalid gift card code": "Kehtetu kinkekaardi kood",
	"Invalid login or password.": "Viga praeguse parooli sisestamisel",
	"Invalid mobile number format": "Vale mobiilinumbri formaat",
	"It is worth being a regular customer of Aatrium Sisustuskaubamaja, because we offer you several discounts and make the purchase process easier for you. You actually already have our loyalty card, all you have to do is log in as a customer with your ID-card, Mobile-ID or Smart-ID": "Aatriumi Sisustuskaubamaja püsiklient tasub olla, sest pakume Sulle mitmeid soodustusi ja teeme ostuprotsessi Sinu jaoks lihtsamaks. Meie kliendikaart on Sul tegelikult juba olemas, pead ennast vaid oma ID-kaardi või Mobiil-IDga või Smart-IDga sisse logima.",
	"January": "Jaanuar",
	"July": "Juuli",
	"June": "Juuni",
	"klevu_price": "Hind",
	"Last item": "Viimane toode",
	"Last name": "Perekonnanimi",
	"Lastname": "Perekonnanimi",
	"Loan partner": "Laenupartner",
	"Log in": "Logi sisse",
	"Log out": "Logi välja",
	"Login": "Logi sisse",
	"Login error": "Sisse logimine ebaõnnestus",
	"Looks like you have not added any items to the Wishlist.": "Toote lisamiseks ostunimekirja peate sisse logima.",
	"Loyalty price": "Püsikliendi hind",
	"Loyalty program": "Lojaalsusprogramm",
	"Loyalty program and newsletter": "Püsikliendi eelised",
	"March": "Märts",
	"May": "Mai",
	"Medium password": "Keskmise tugevusega parool",
	"Menu": "Menüü",
	"Message": "Sõnum",
	"Minimum of different classes of characters in password is 3. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.": "Parool peab koosnema minimaalselt väiksest tähest, suurest tähest, numbrist ja erimärgist.",
	"Minimum value 10 EUR": "Miinimum väärtus 10 EUR",
	"Mobile number": "Mobiili number",
	"Mobile-ID": "Mobiil-ID",
	"month": "kuud",
	"Monthly payment from": "Kuumakse alates",
	"More information": "Rohkem infot",
	"My account": "Minu konto",
	"My orders": "Minu tellimused",
	"My profile": "Minu profiil",
	"My purchase history": "Minu ostuajalugu",
	"My wishlist": "Minu soovilist",
	"Name": "Nimi",
	"new": "uus",
	"New password": "Uus parool",
	"New products": "Uued tooted",
	"Newsletter subscribed": "Uudiskiri tellitud",
	"Newsletter subscription": "Uudiskirja tellimine",
	"Newsletter subscription successful": "Olete liitunud uudiskirja saajatega",
	"Newsletter unsubscribed": "Uudiskirja tellimus on tühistatud",
	"Newsletter unsubscription successful": "Uudiskirjast loobutud",
	"No": "Ei",
	"No products in cart": "Ostukorvis ei ole tooteid",
	"No products in wishlist": "Soovilistis tooted puuduvad",
	"No results found": "Tulemusi ei leitud",
	"No, keep account": "Tühista",
	"No, keep address": "Ei, hoidke aadressi",
	"No, keep agreement": "Ei, säilitage leping",
	"November": "November",
	"Number of items to utilize": "Vali utiliseeritavate esemete arv",
	"October": "Oktoober",
	"Office table": "Kontorilaud",
	"OK": "OK",
	"On order": "Tellimisel",
	"or": "või",
	"or continue as a guest by filling out the following information": "või jätka külalisena ja täida allolevad väljad",
	"Order": "Tellige",
	"Order history": "Tellimuste ajalugu",
	"Order information was also sent to your email address. We will notify you as soon as the goods are complete. You can also track your order from the \"My Purchase History\" page in your account.": "Tellimuse info saadeti ka teie e-posti aadressile. Teavitame teid kohe, kui kaup on komplekteeritud. Oma eelnevaid tellimusi saab jälgida ka oma konto lehelt \"Tellimuste ajalugu\".",
	"Order more": "Tellides",
	"Order no:": "Tellimus number:",
	"Order number": "Tellimuse number",
	"ORDER_HISTORY_PAGE_TITLE": "Tellimuste ajalugu",
	"Out of stock": "Läbi müüdud",
	"Package": "Pakendi",
	"Packaging": "Pakend",
	"Page not found": "Lehekülge ei leitud",
	"Paper gift card": "Paberil kinkekaart",
	"PARCEL_MACHINE_DELIVERY_TITLE": "SmartPost pakiautomaat",
	"Password": "Parool",
	"Password change": "Vaheta parool",
	"Password changed": "Parool vahetatud",
	"Password must contain of at least {length} characters": "Parool peab koosnema vähemalt {length} märgist",
	"PASSWORD_CHANGE_PAGE_TITLE": "Vaheta parool",
	"Passwords do not match": "Paroolid ei ühildu",
	"Passwords don't match": "Paroolid ei ühildu",
	"Payment": "Makseviis",
	"pc": "tk",
	"Pending": "Ootab",
	"Per month": "kuus",
	"Period": "Periood",
	"Personal": "Isiklik",
	"Personal data": "Kontaktandmed",
	"Personal data has been updated": "Andmed uuendatud",
	"PERSONAL_PAGE_TITLE": "Kontaktandmed",
	"Phone": "Mobiiltelefoni number",
	"PICKUP_DELIVERY_TITLE": "Ise järgi",
	"Place order": "Vormista ost",
	"Please add some products to your cart first": "Jätkake ostlemist ja lisage tooteid ostukorvi",
	"Please add some products to your wishlist first": "Te pole ostunimekirja veel tooteid lisanud",
	"Please check spam folder": "Kontrolli, kas e-mail on spämmi kaustas",
	"Please check that the ID card reader is connected to the computer and that the ID card is in the card reader. If that doesn't work, restart your browser and try again. Check that the ID card certificates have been updated.": "Palun kontrolli, et ID-kaardi lugeja oleks arvutiga ühendatud ning et ID-kaart oleks kaardilugejas. Kui see ei aita taaskäivita brauser ja proovi uuesti. Kontolli, et ID-kaardi sertifikaadid on uuendatud.",
	"Please check the code!": "Palun kontrollige koodi!",
	"Please create a new password": "Palun loo uus parool",
	"Please enter a valid e-mail address": "Palun sisesta kehtiv e-posti aadress",
	"Please enter the email associated with your account and we will send an email with instructions to reset your password.": "Sisestage oma kontoga seotud e-posti aadress ja me saadame teile parooli lähtestamise juhistega meili.",
	"Please fill this field to continue": "Palun täitke see väli, et jätkata",
	"Please Log In": "Palun logi sisse",
	"Please select the appropriate method to access your account": "Palun vali sobiv meetod kontole sisenemiseks",
	"Please select the product features": "Palun vali toote omadused",
	"Please try again": " Palun proovi uuesti.",
	"Popular searches": "Populaarsed otsingud",
	"Position": "Positsiooni",
	"price": "hind",
	"Price": "Hind",
	"price from": "alates",
	"Prior and explicit consent must be obtained before any activation of cookies (apart from whitelisted, necessary cookies).": "Prior and explicit consent must be obtained before any activation of cookies (apart from whitelisted, necessary cookies).",
	"Proceed to checkout": "Jätkake kassasse",
	"Product": "Toote",
	"Product added to cart": "Toode lisati ostukorvi",
	"Product added to wishlist": "Toode lisatud soovilisti",
	"Product available": "Toode saadaval",
	"Product dimentsions": "Toote mõõdud",
	"Product has been added to the cart!": "Toode on lisatud ostukorvi!",
	"Product id": "Tootekood",
	"product in shopping cart": "toode ostukorvis",
	"Product information": "Tooteinfo",
	"Product not available": "Toode pole saadaval",
	"Product removed from cart": "Toode on eemaldatud ostukorvist",
	"Product removed from wishlist": "Toode soovilistist eemaldatud",
	"Product suggestions": "Soovitatud tooted",
	"products": "Tooted",
	"Products": "Toodet",
	"Products added to cart": "Tooteid lisati ostukorvi",
	"Products in cart:": "Tooteid ostukorvis:",
	"products in shopping cart": "toodet ostukorvis",
	"Products in wishlist:": "Tooteid soovilistis:",
	"Products not found": "Tooteid ei leitud",
	"Products per page": "Tooteid lehel",
	"Proposals": "Ettepanekud",
	"Quantity": "Kogus",
	"Quick delivery": "Kiirelt kätte",
	"Re-enter the new password": "Sisestage uus parool uuesti",
	"Recent searches": "Hiljutised otsingud",
	"Recently viewed products": "Viimati vaadatud tooted",
	"Register": "Registreeri",
	"Registry code": "Registri kood",
	"Registry code must contain 8 numbers": "Registrikood peab koosnema 8 numbrist",
	"Relevant first": "Vastavus",
	"Removable covers": "Eemaldatav kate",
	"Remove": "Eemalda",
	"Remove default address": "Eemalda vaikimisi aadress",
	"Remove default address?": "Eemaldada vaikimisi aadress?",
	"Remove filter": "Tühista filter",
	"Repeat new password": "Korda uut parooli",
	"Repeat order": "Korduv tellimus",
	"Repeat your new password": "Korda uut parooli",
	"Request data": "Saada päring",
	"Resend Email": "Uus parool",
	"Reset": "Tühista",
	"Reset (filter name) filter": "Tühista (filter name) filter",
	"Reset all": "Tühista Kõik",
	"Reset password": "Taasta parool",
	"Return to homepage": "Avalehele",
	"SALES_HALL_AND_WAREHOUSES_OPEN_HOURS": "Müügisaal ja laod on avatud E-L 10-20, P 10-19",
	"Save changes": "Salvesta",
	"Search address": "Aadressi otsing",
	"Search for products or brands...": "Otsi tooteid või kaubamärke…",
	"Search products": "Otsi tooteid",
	"Search results": "Otsingu tulemus",
	"Search...": "Search...",
	"Select an address please": "Palun vali aadress",
	"Select delivery method": "Vali tarnemeetod",
	"Select deposit": "Vali sissemakse",
	"Select interest rate": "Vali intressimäär",
	"Select loan partner": "Vali laenupartner",
	"Select one of payment options": "Palun vali maksemeetod",
	"Select parcel machine": "Valige pakiautomaat",
	"Select payment method": "Vali makseviis",
	"Select period": "Vali periood",
	"September": "September",
	"services": "Teenused",
	"Services": "Teenused",
	"Shelves": "Riiul",
	"Shipping": "Tarneviis",
	"Shipping address has been removed": "Aadress eemaldatud",
	"Shipping address has been saved": "Aadress salvestatud",
	"Shipping details": "Aadressid",
	"SHIPPING_DETAILS_PAGE_TITLE": "Aadressid",
	"Shoe cabinet": "Jalatsikapp",
	"Show all": "Näita kõiki",
	"Show all products in category": "Näita kõiki kategooria tooteid",
	"Sideboard": "Sektsioon-puhvetikapp",
	"Similar products": "Sarnased tooted",
	"Something went wrong": "Midagi läks valesti",
	"Sort by": "Sorteeri",
	"SPECIAL_COURIER_DELIVERY_TITLE": "SmartPost kuller",
	"Status": "Staatus",
	"Stiffness": "Jäikus",
	"Store contacts": "Kontaktid",
	"Strong password": "Tugev parool",
	"Subscribe": "Liitu uudiskirjaga",
	"Subscribe to newsletter": "Telli uudiskiri",
	"Subscribe to our newsletter": "Liitu meie uudiskirjaga",
	"SUBSCRIPTIONS_PAGE_TITLE": "Püsikliendi eelised",
	"Subtotal": "Vahesumma",
	"Sum": "Summa",
	"Summary": "Tellimuse ülevaade",
	"Tax ID": "KMKR number",
	"Thank you for subscribing to newsletter!": "Täname Teid uudiskirjaga liitumast!",
	"Thank you for your order!": "Tellimus on vastu võetud!",
	"Thank you!": "Aitäh!",
	"THANK_YOU_PAGE_TITLE": "Tellimuse kinnitus",
	"That is why end-user consent to cookies is the GDPR is most used legal basis that allows websites to process personal data and use cookies.": "That is why end-user consent to cookies is the GDPR is most used legal basis that allows websites to process personal data and use cookies.",
	"The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "E-post või parool oli vale.",
	"The customer with the current identification code already exist.": "Selle isikukoodiga kasutaja on juba registreeritud.",
	"The field should contain only numbers": "Väli peaks sisaldama ainult numbreid",
	"The GDPR requires a website to only collect personal data from users after they have given their explicit consent to the specific purposes of its use.": "The GDPR requires a website to only collect personal data from users after they have given their explicit consent to the specific purposes of its use.",
	"The General Data Protection Regulation (GDPR) is an EU legislation that governs all collection and processing of personal data from individuals inside the EU.": "The General Data Protection Regulation (GDPR) is an EU legislation that governs all collection and processing of personal data from individuals inside the EU.",
	"The message has been sent to your phone.": "Sõnum on saadetud Teie telefonile.",
	"The number should be in the shape of EE123456789": "Number peaks olema kuju EE123456789",
	"The page was not found. You may have mistyped the address or the page may have moved": "Me ei leidnud soovitud lehte üles. Proovi midagi otsida või mine Aatriumi avalehele.",
	"The password needs at least 8 characters. Create a new password and try again.": "Parool peab koosnema vähemalt 8 märgist",
	"This email address already has been used": "Antud e-posti aadress on juba uudiskirja saaja",
	"This gift card is already applied": "See kinkekaart on juba rakendatud",
	"This is because one of the most common ways for personal data to be collected and shared online is through website cookies. The GDPR sets out specific rules for the use of cookies.": "This is because one of the most common ways for personal data to be collected and shared online is through website cookies. The GDPR sets out specific rules for the use of cookies.",
	"To": "Kuni",
	"to the data protection conditions": "andmekaitsetingimustega",
	"To whow": "Kellele",
	"Transport": "Transport",
	"Type": "Tüüp",
	"Type company name": "Kirjutage ettevõtte nimi",
	"Type gift card code": "Sisestage kinkekaardi kood",
	"Type registry code": "Tüüp registrikood",
	"Type tax ID": "Tüüp KMKR number",
	"Typically, GDPR cookie compliance is achieved on websites through cookie banners that allow users to select and accept certain cookies for activation rather than others, when visiting a site.": "Typically, GDPR cookie compliance is achieved on websites through cookie banners that allow users to select and accept certain cookies for activation rather than others, when visiting a site.",
	"Under the EU is GDPR, it is the legal responsibility of website owners and operators to make sure that personal data is collected and processed lawfully": "Under the EU is GDPR, it is the legal responsibility of website owners and operators to make sure that personal data is collected and processed lawfully",
	"Up to {p} month • {r}%": "Kuni {p} kuud • {r}%",
	"Up to 12 month • 3%": "Kuni 12 kuud • 3%",
	"Up to 3 month • 0%": "Kuni 3 kuud • 0%",
	"Up to 6 month • 1%": "Kuni 6 kuud • 1%",
	"Update data": "Uuenda andmeid",
	"Update password": "Uuenda parool",
	"Use a gift card": "Kinkekaart",
	"User has been deleted": "Kasutaja on kustutatud",
	"Valid until": "Kehtib kuni",
	"VAT": "Käibemaks",
	"Very strong password": "Tugev parool",
	"View": "Vaata",
	"View all": "Vaata kõiki",
	"View order": "Vaata tellimust",
	"Want to stay up to date with our best offers and interior design news? Subscribe to Aatrium's newsletter!": "Soovid olla kursis meie parimate pakkumiste ja sisustusvaldkonna uudistega? Telli Aatriumi uudiskiri!",
	"We are under maintenance": "Käimas on hooldustööd",
	"We can't find products matching the selection.": "Sobivaid tooteid ei leitud",
	"We have sent instructions for password reset at": "Saatsime parooli taastamise juhendi",
	"We’ll be back shortly": "Oleme peagi tagasi",
	"Weak password": "Nõrk parool",
	"Websites must comply with the following GDPR cookie requirements:": "Websites must comply with the following GDPR cookie requirements:",
	"weight": "Kaal",
	"Weight": "Kaal",
	"What is next?": "Mis edasi?",
	"What should be changed?": "Mida me peaksime muutma?",
	"When selecting Aatrium home delivery, you can add furniture utilisation and/or furniture assembly service.": "Koju kätte tarnega on võimalik juurde valida vana mööbli utiliseerimise ja/või uue mööbli kokkupanemise teenus.",
	"width": "Laius",
	"Width": "Laius",
	"With invoice": "Arvega",
	"Without VAT": "Käibemaksuta",
	"Yes": "Jah",
	"Yes, cancel agreement": "Jah, tühistage leping",
	"Yes, delete account": "Kustuta konto",
	"Yes, delete address": "Jah, kustuta aadress",
	"Yes! Change my order to forest-positive.": "Jah! Muutke minu tellimus metsa-positiivseks.",
	"You are already subscribed to newsletter with this e-mail address": "Sellele e-maili aadressile on juba uudiskiri tellitud",
	"You are not authorized, please log in.": "Sessioon aegus. Palun logi uuesti sisse.",
	"You can add your shipping address here. It will be set as default during checkout.": "Saate lisada oma tarneaadressi siin. See määratakse vaikimisi kassas.",
	"You can change or add default address any time later, or you can select shipping address during checkout.": "Saate hiljem igal ajal muuta või lisada vaikimisi aadressi või valida tarneaadressi tellimuse vormistamise ajal.",
	"You can change the password to access your account.": "Saate muuta oma kontole juurdepääsu parooli.",
	"You can join with loyalty program after Mobile Id, ID Card or Smart id authentication.": "Lojaalsusprogrammiga saab liituda pärast Mobiil-ID, ID-kaardi või Smart id autentimist.",
	"You can see you orders and order details here": "Saate näha oma tellimusi ja tellimuse üksikasju siin",
	"You have found a 404 page!": "Me ei leidnud soovitud lehte üles.",
	"You must be logged in to use wishlist": "Toote lisamiseks ostunimekirja peate sisse logima",
	"You must enter the Mobile ID / Smart-ID PIN1 code on your phone to continue.": "Jätkamiseks tuleb Teil oma telefoni sisestada Mobiil-ID / Smart-ID PIN1-kood.",
	"You will be <b>logged out</b> from your account. Next time when you log in, new GDPR agreement document has to be accepted in order to continue using your account.": "Teid logitakse oma <b>kontolt välja</b>. Järgmisel korral, kui logite sisse, peate oma konto kasutamise jätkamiseks nõustuma uue GDPR-lepingu dokumendiga.",
	"You will permanently delete your account with all related information. This action can not be reverted.": "Ostutehingutega seotud isikuandmeid säilitatakse vastavalt raamatupidamisseadusele 7 aastat. Püsikliendiprogrammiga seotud andmeid säilitatakse kuni klient on avaldanud kirjalikult soovist astuda püsikliendiprogrammist välja. Videovalve käigus kogutud isikuandmeid säilitatakse kuni 1 kuu, v.a tuvastatud õigusrikkumiste ja muude turvaintsidentide puhul. Sellisel juhul säilitatakse asjakohaseid salvestusi kuni juhtumi lahendamiseni. Otseturundusega seotud isikuandmeid säilitame seni, kuni olete avaldanud soovi otseturundusteadete saamisest loobuda.",
	"Your bag is empty": "Ostunimekiri on tühi",
	"Your cart is empty": "Ostukorv on tühi",
	"Your e-mail...": "Sinu email...",
	"Your email": "Sinu email",
	"Your full name": "Sinu täispikk nimi",
	"Your name": "Sinu nimi",
	"Your verification code is": "Teie kontrollkood on",
	"Your wishlist is empty": "Ostunimekiri on tühi"
}