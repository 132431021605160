import { CustomQuery, UseContextReturn } from '~/types/core'
import type { Cart, SetShippingMethodsOnCartInput } from '~/modules/GraphQL/types'
import { UseShippingProviderError } from '~/modules/checkout/composables/useShippingProvider'

export const setShippingMethodsOnCartCommand = {
  execute: async (context: UseContextReturn, shippingMethodParams: SetShippingMethodsOnCartInput, customQuery: CustomQuery): Promise<Cart | UseShippingProviderError | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.setShippingMethodsOnCart(shippingMethodParams, customQuery)

    if (errors) {
      return {
        error: {
          message: errors[0].message
        }
      }
    }

    // TODO: Find out why 'Cart' doesn't match the type of the response data.
    return (data?.setShippingMethodsOnCart?.cart as unknown as Cart) ?? null
  }
}
