export const retrieveAsyncAuthRequestStatus: string = `
  query getAsyncAuthRequestStatus ($request_id: String!, $request_secret: String!) {
    retrieveAsyncAuthRequestStatus (
      request_id: $request_id
      request_secret: $request_secret
    ) {
      status
      auth_response {
          token
          data {
              key
              value
          }
      }
    }
  }
`;
