
import { defineComponent, ref, watch } from '@nuxtjs/composition-api'
import Overlay from '~/components/Overlay.vue'
import { useUiState } from '~/composables'

export default defineComponent({
  name: 'LayoutSidebarsPortal',
  components: {
    Overlay
  },
  setup () {
    const uiState = useUiState()
    const hasOneSidebarsOpened = ref<string[]>([])

    watch(uiState.state, () => {
      for (const [key, sidebarConfig] of uiState.LayoutsSidebarsEntries) {
        if (uiState.state[sidebarConfig.isVisibleKey] && !hasOneSidebarsOpened.value.includes(key)) {
          hasOneSidebarsOpened.value.push(key)
        }
      }
    })

    return { uiState, hasOneSidebarsOpened }
  }
})
