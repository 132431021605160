// eslint-disable-next-line import/no-default-export
export default `#graphql
  query productsList($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        __typename
        id
        uid
        sku
        name
        stock_status
        only_x_left_in_stock
        new_to_date
        tarneaeg
        media_gallery {
          url
          position
          disabled
          label
        }
        availability_in_stores {
          qty
          source_name
          source_code
          status
        }
        thumbnail {
          url
          position
          disabled
          label
        }
        image {
          url
          position
          disabled
          label
        }
        url_key
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        available_price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
      }
    }
  }
`
