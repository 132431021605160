import { defineStore } from 'pinia'
import type { AatriumCustomer } from '~/modules/customer/types/AatriumCustomer'

interface CustomerState {
  user: AatriumCustomer | null
  isLoggedIn: boolean
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false
  }),
  actions: {
    setIsLoggedIn (isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn
    }
  }
})
