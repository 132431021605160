import cookieUniversal from 'cookie-universal'
import type { Inject } from '@nuxt/types/app'
import type { CookieSerializeOptions } from 'cookie'

// eslint-disable-next-line import/no-default-export,@typescript-eslint/explicit-function-return-type
export default ({ req, res }, inject: Inject) => {
// eslint-disable-next-line @typescript-eslint/quotes
  const options = JSON.parse(`{
  "alias": "cookies",
  "parseJSON": true
}`)
  const cookies = cookieUniversal(req, res, options.parseJSON)

  const originalSet = cookies.set

  cookies.set = function (...args: [string, CookieSerializeOptions]) {
    if (!res?.writableEnded) {
      return originalSet(...args)
    }
  }

  inject(options.alias, cookies)
}
