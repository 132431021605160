import { ref, useContext, shallowReadonly, Ref } from '@nuxtjs/composition-api'
import type { UseCategorySearchErrors, UseCategorySearchInterface } from './useCategorySearch'
import { Logger } from '~/helpers/logger'
import type { CategorySearchQueryVariables, CategoryTree } from '~/modules/GraphQL/types'
import { ComposableFunctionArgs } from '~/composables'

/**
 * Allows searching for categories. It is
 * commonly used in subtrees navigation.
 *
 * See the {@link UseCategorySearchInterface} for a list of methods and values available in this composable.
 */
export function useCategorySearch (): UseCategorySearchInterface {
  const { app } = useContext()
  const loading = ref(false)
  const error = ref<UseCategorySearchErrors>({
    search: null
  })
  const result = ref<CategoryTree[] | null>(null)

  async function search (params: ComposableFunctionArgs<CategorySearchQueryVariables>, useFullProjection: boolean = false): Promise<void> {
    Logger.debug('useCategory/search', params)

    try {
      loading.value = true
      const { filters, useFullProjection } = params
      const { data } = await app.context.$vsf.$magento.api.categorySearch(
        { filters },
        { categorySearch: (useFullProjection) ? 'fullCategorySearch' : 'categorySearch' })

      Logger.debug('[Result]:', { data })

      result.value = data.categoryList
      error.value.search = null
    } catch (err) {
      error.value.search = err
      result.value = null
      Logger.error('useCategory/search', err)
    } finally {
      loading.value = false
    }
  }

  async function resolveCategories (resolvedUid: string, path: string): Promise<void> {
    Logger.debug('useCategory/resolveCategories', { resolvedUid, path })

    try {
      loading.value = true

      const urlPathArray = path.replace('.html', '')
        .split('/')
        .filter(Boolean)
        .reduce<string[]>((arr, categoryPath) => {
        arr.push(
          (arr.length) ? `${arr[arr.length - 1]}/${categoryPath}` : categoryPath
        )
        return arr
      }, [])

      // Remove last el, it's current active category
      urlPathArray.pop()

      const { data } = await app.context.$vsf.$magento.api.categorySearch(
        {
          activeCategoryUid: resolvedUid,
          parentCategoriesPath: urlPathArray
        },
        { categorySearch: 'resolveCategories' })

      Logger.debug('[Result]:', { data })

      data.parentCategories.sort((x, y) => x.level - y.level)

      result.value = [
        ...data.parentCategories,
        data.activeCategory[0]
      ]
      error.value.search = null
    } catch (err) {
      error.value.search = err
      result.value = null
      Logger.error('useCategory/search', err)
    } finally {
      loading.value = false
    }
  }

  return {
    search,
    resolveCategories,
    loading: shallowReadonly(loading),
    error: error as Readonly<Ref<UseCategorySearchErrors>>,
    result: result as Readonly<Ref<CategoryTree[] | null>>
  }
}

export * from './useCategorySearch'
// eslint-disable-next-line import/no-default-export
export default useCategorySearch
