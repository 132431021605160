import { readonly, ref, useContext } from '@nuxtjs/composition-api'
import { UseUserOrderErrors, UseUserOrderInterface, UseUserOrderSearchParamsWithSort } from './useUserOrder'
import { Logger } from '~/helpers/logger'
import { CustomerOrders, CustomerOutput } from '~/modules/GraphQL/types'
import { customer } from '~/modules/customer/aatrium/graphql/customer'

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder (): UseUserOrderInterface {
  const { app } = useContext()
  const loading = ref(false)
  const error = ref<UseUserOrderErrors>({
    search: null
  })

  const search = async (params: UseUserOrderSearchParamsWithSort): Promise<CustomerOrders> => {
    let results = null

    try {
      loading.value = true

      Logger.debug('[Magento] search user orders', { params })

      const { data } = await app.$vsf.$magento.api.customQuery({ query: customer, queryVariables: params })

      Logger.debug('[Result]:', { data })

      results = (data as CustomerOutput)?.customer?.orders ?? null
      error.value.search = null
    } catch (err) {
      error.value.search = err
      Logger.error('useRelatedProducts/search', err)
    } finally {
      loading.value = false
    }

    return results
  }

  return {
    search,
    loading: readonly(loading),
    error: readonly(error)
  }
}

// eslint-disable-next-line import/no-default-export
export default useUserOrder
export * from './useUserOrder'
