import { defineStore } from 'pinia'
import { ShippingCartAddress } from '~/modules/GraphQL/types'

interface ShippingDataStore {
  shippingData: ShippingCartAddress | null
}

export const useShippingDataStore = defineStore('shippingDataStore', {
  state: (): ShippingDataStore => ({
    shippingData: null
  })
})
