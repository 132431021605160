import { EstAuthCustomerType, IEstAuthMethod } from '../types';
import { VsfContext } from "../../context";
import { AuthSidebarMode } from "../../useUiState/enums/AuthSidebarMode";
import { EstAuthError } from "../authError";

export default (context: VsfContext, customerType: EstAuthCustomerType): IEstAuthMethod => {
  const { $magento: { config: magentoConfig } } = context;
  debugger;
  const generateCode = async (inputData, mode: AuthSidebarMode) => {
    const returnPage = new URL(location.href);
    returnPage.searchParams.append('mode', mode);
    returnPage.searchParams.append('customer_type', customerType);

    if (typeof magentoConfig.cardIdAuthUrl != 'string' || !magentoConfig.cardIdAuthUrl) {
      throw new EstAuthError(`Configuration is bad`, `cardIdAuthUrl is not defined in configuration`);
    }

    location.replace(magentoConfig.cardIdAuthUrl.replace('{RETURN_PAGE}', encodeURIComponent(returnPage.toString())));
    throw null;
  };

  return {
    generateCode
  };
}
