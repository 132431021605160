<template>
  <div class="copyright">
    &copy; Copyright Aatrium Sisustuskaubamaja {{ currentYear }} |
    <a target="_blank" href="https://www.zaproo.ee/">
      Zaproo eCommerce
    </a>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api'

export default {
  name: 'AppCopyright',
  setup () {
    const currentYear = computed(() => new Date().getFullYear())

    return { currentYear }
  }
}
</script>

<style lang="scss" scoped>
.copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: var(--c-light-06);

  text-align: center;

  font-size: 14px;
  line-height: 24px;

  padding: 20px;

  @include for-tablets-up {
    flex-direction: row;
    column-gap: 6px;
  }

  a {
    &:hover {
      color: var(--c-white);
    }
  }
}
</style>
