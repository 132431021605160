import { computed, reactive, ref } from '@nuxtjs/composition-api'
import { cloneDeep } from 'lodash-es'
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState'
import { AuthSidebarMode } from '~/composables/useUiState/enums/AuthSidebarMode'
import { AuthSidebarModes } from '~/composables/useUiState/variables/auth-sidebar-modes'
import { LAYOUTS_SIDEBARS } from '~/composables/useUiState/variables/LayoutsSidebars'
import { getInstance } from '~/helpers/hooks/getInstance'

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState (): UseUiStateInterface {
  const app = getInstance()

  if (!(app.proxy.$root as any).__UI_STATE__) {
    (app.proxy.$root as any).__UI_STATE__ = reactive<StateInterface>({
      isAuthSidebarOpen: false,
      isCartSidebarOpen: false,
      isWishlistSidebarOpen: false,
      isLoginModalOpen: false,
      isNewsletterModalOpen: false,
      isCategoryGridView: true,
      isWishlistGridView: true,
      isFilterSidebarOpen: false,
      isMobileMenuOpen: false,
      isMegaMenuSidebarOpen: false,
      isDeliverySidebarOpen: false,
      isDropdownSidebarOpen: false,
      isMyAccountSidebarOpen: false,
      isAccountDeletionSidebarOpen: false,
      isMyAccountConfirmationSidebarOpen: false,
      isGdprModalOpen: false,
      isSearchOpen: false,
      authSidebarMode: AuthSidebarMode.login,
      isAssemblyProductsSidebarOpen: false,
      isErrorPageView: false,
      isManagedNewsletterModalOpen: false
    })
  }

  const USE_UI_STATE = (app.proxy.$root as any).__UI_STATE__ as StateInterface

  const toggleAuthSidebar = (mode?: AuthSidebarMode): void => {
    USE_UI_STATE.isAuthSidebarOpen = !USE_UI_STATE.isAuthSidebarOpen
    if (mode) {
      USE_UI_STATE.authSidebarMode = mode
    }
  }

  const toggleMobileMenu = (): void => {
    USE_UI_STATE.isMobileMenuOpen = !USE_UI_STATE.isMobileMenuOpen
  }

  const toggleCartSidebar = (): void => {
    if (USE_UI_STATE.isMobileMenuOpen) toggleMobileMenu()
    USE_UI_STATE.isCartSidebarOpen = !USE_UI_STATE.isCartSidebarOpen
  }

  const toggleWishlistSidebar = (): void => {
    if (USE_UI_STATE.isMobileMenuOpen) toggleMobileMenu()
    USE_UI_STATE.isWishlistSidebarOpen = !USE_UI_STATE.isWishlistSidebarOpen
  }

  const toggleLoginModal = (): void => {
    if (USE_UI_STATE.isMobileMenuOpen) toggleMobileMenu()
    USE_UI_STATE.isLoginModalOpen = !USE_UI_STATE.isLoginModalOpen
  }

  const toggleNewsletterModal = (): void => {
    USE_UI_STATE.isNewsletterModalOpen = !USE_UI_STATE.isNewsletterModalOpen
  }

  const toggleGdprModal = (): void => {
    USE_UI_STATE.isGdprModalOpen = !USE_UI_STATE.isGdprModalOpen
  }

  const changeToCategoryGridView = (): void => {
    USE_UI_STATE.isCategoryGridView = true
  }
  const changeToCategoryListView = (): void => {
    USE_UI_STATE.isCategoryGridView = false
  }

  const changeToWishlistGridView = (): void => {
    USE_UI_STATE.isWishlistGridView = true
  }

  const changeToWishlistListView = (): void => {
    USE_UI_STATE.isWishlistGridView = false
  }
  const toggleFilterSidebar = (): void => {
    USE_UI_STATE.isFilterSidebarOpen = !USE_UI_STATE.isFilterSidebarOpen
  }

  const isMegaMenuSidebarOpen = computed(() => USE_UI_STATE.isMegaMenuSidebarOpen)
  const toggleMegaMenuSidebar = (): void => {
    USE_UI_STATE.isMegaMenuSidebarOpen = !USE_UI_STATE.isMegaMenuSidebarOpen
  }

  const toggleDeliverySidebar = (): void => {
    USE_UI_STATE.isDeliverySidebarOpen = !USE_UI_STATE.isDeliverySidebarOpen
  }

  const toggleAssemblyProductsSidebar = (): void => {
    USE_UI_STATE.isAssemblyProductsSidebarOpen = !USE_UI_STATE.isAssemblyProductsSidebarOpen
  }

  const isDropdownSidebarOpen = computed(() => USE_UI_STATE.isDropdownSidebarOpen)

  const toggleDropdownSidebar = (): void => {
    USE_UI_STATE.isDropdownSidebarOpen = !USE_UI_STATE.isDropdownSidebarOpen
  }

  const toggleMyAccountSidebar = (): void => {
    USE_UI_STATE.isMyAccountSidebarOpen = !USE_UI_STATE.isMyAccountSidebarOpen
  }

  const toggleAccountDeletionSidebar = (): void => {
    USE_UI_STATE.isAccountDeletionSidebarOpen = !USE_UI_STATE.isAccountDeletionSidebarOpen
  }

  const toggleMyAccountConfirmationSidebarOpen = (): void => {
    USE_UI_STATE.isMyAccountConfirmationSidebarOpen = !USE_UI_STATE.isMyAccountConfirmationSidebarOpen
  }

  const isSearchOpen = computed(() => USE_UI_STATE.isSearchOpen)
  const toggleSearch = (): void => {
    USE_UI_STATE.isSearchOpen = !USE_UI_STATE.isSearchOpen
  }

  const setAuthSidebarMode = (mode: AuthSidebarMode): void => {
    USE_UI_STATE.authSidebarMode = mode
  }

  const changeToErrorPageView = (): void => {
    USE_UI_STATE.isErrorPageView = true
  }
  const changeToDefaultPageView = (): void => {
    USE_UI_STATE.isErrorPageView = false
  }

  const toggleManagedNewsletterModal = (): void => {
    USE_UI_STATE.isManagedNewsletterModalOpen = !USE_UI_STATE.isManagedNewsletterModalOpen
  }

  return {
    state: USE_UI_STATE,
    LayoutsSidebars: LAYOUTS_SIDEBARS,
    LayoutsSidebarsEntries: Object.entries(LAYOUTS_SIDEBARS),
    isAuthSidebarOpen: computed(() => USE_UI_STATE.isAuthSidebarOpen),
    isMobileMenuOpen: computed(() => USE_UI_STATE.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => USE_UI_STATE.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => USE_UI_STATE.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => USE_UI_STATE.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => USE_UI_STATE.isNewsletterModalOpen),
    isCategoryGridView: computed(() => USE_UI_STATE.isCategoryGridView),
    isWishlistGridView: computed(() => USE_UI_STATE.isWishlistGridView),
    isFilterSidebarOpen: computed(() => USE_UI_STATE.isFilterSidebarOpen),
    isDeliverySidebarOpen: computed(() => USE_UI_STATE.isDeliverySidebarOpen),
    isMyAccountSidebarOpen: computed(() => USE_UI_STATE.isMyAccountSidebarOpen),
    isAccountDeletionSidebarOpen: computed(() => USE_UI_STATE.isAccountDeletionSidebarOpen),
    isMyAccountConfirmationSidebarOpen: computed(() => USE_UI_STATE.isMyAccountConfirmationSidebarOpen),
    isGdprModalOpen: computed(() => USE_UI_STATE.isGdprModalOpen),
    authSidebarMode: computed(() => USE_UI_STATE.authSidebarMode),
    authSidebarModes: ref(cloneDeep(AuthSidebarModes)),
    isAssemblyProductsSidebarOpen: computed(() => USE_UI_STATE.isAssemblyProductsSidebarOpen),
    isErrorPageView: computed(() => USE_UI_STATE.isErrorPageView),
    isMegaMenuSidebarOpen,
    isDropdownSidebarOpen,
    isSearchOpen,
    isManagedNewsletterModalOpen: computed(() => USE_UI_STATE.isManagedNewsletterModalOpen),
    toggleAuthSidebar,
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleMegaMenuSidebar,
    toggleDeliverySidebar,
    toggleDropdownSidebar,
    toggleMyAccountSidebar,
    toggleAccountDeletionSidebar,
    toggleMyAccountConfirmationSidebarOpen,
    toggleGdprModal,
    toggleSearch,
    setAuthSidebarMode,
    toggleAssemblyProductsSidebar,
    changeToErrorPageView,
    changeToDefaultPageView,
    toggleManagedNewsletterModal
  }
}

// eslint-disable-next-line import/no-default-export
export default useUiState
export * from './useUiState'
