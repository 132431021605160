// select the target node
const target = document.querySelector('title')

/**
 * This plugin just prevents a first title modification after app initialization, because we are using lazy hydrate for router outlet
 */
const observer = new MutationObserver(function (mutations) {
  if (!mutations.length || mutations[0].type !== 'childList') {
    return
  }

  const oldValue = mutations[0].removedNodes[0].textContent
  const newValue = mutations[0].addedNodes[0].textContent

  if (newValue === 'aatrium.ee') {
    mutations[0].addedNodes[0].textContent = oldValue
    observer.disconnect()
  }
})

observer.observe(target, { childList: true })
