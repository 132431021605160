import { GtagAatriumProductInput, GtagProductItem } from '~/modules/aatrium-gtm'
import { getPrice } from '~/modules/catalog/product/getters/productGetters'

export function preparedGtagItems (products: GtagAatriumProductInput[]): GtagProductItem[] {
  return products.map((product, idx) => {
    const { regular, special, final } = getPrice(product.product)
    return {
      item_id: product.product.sku,
      item_name: product.product.name,
      item_category: product.product?.categories?.map(category => category.name)?.join(' -> ') ?? '',
      discount: special ? +((regular - special).toFixed(2)) : 0,
      price: final,
      quantity: product?.quantity ?? 1,
      index: product.index ?? idx
    }
  })
}
