import GetRecentProductListCommandQuery from './getRecentProductListCommand.query'
import { GetProductListExecute, Products, useApi } from '~/composables'
import { GetProductSearchParams } from '~/modules/catalog/product/types'

export function getRecentProductListCommand (): GetProductListExecute {
  const { query } = useApi()

  async function execute (searchParams: GetProductSearchParams): Promise<Products | null> {
    const { data } = await query<{ products: Products }>(GetRecentProductListCommandQuery, searchParams)

    return data?.products ?? null
  }

  return {
    execute
  }
}
