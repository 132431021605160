import { cloneDeep } from 'lodash-es'
import { AatriumPaymentGroup } from '../types'
import { AatriumPaymentMethodsReorderConfig as reorderConfig } from '../variables'

export function reorderPaymentMethods (groups: AatriumPaymentGroup[] = []): AatriumPaymentGroup[] {
  const cloneGroups = cloneDeep(groups)
  const configKeys = Object.keys(reorderConfig)

  cloneGroups.sort((x, y) => (
    configKeys?.includes(x.groupCode) ? configKeys?.indexOf(x.groupCode) - configKeys?.indexOf(y.groupCode) : 1)
  )

  cloneGroups.forEach((i) => {
    if (!reorderConfig[i.groupCode]) {
      return
    }

    i.methods.sort((x, y) => {
      const xIndex = reorderConfig[i.groupCode]?.indexOf(x.code)
      const yIndex = reorderConfig[i.groupCode]?.indexOf(y.code)

      // todo: Handle cases where both x.code and y.code are found in reorderConfig[i.groupCode] or either of them is not found
      return (xIndex !== -1 && yIndex !== -1) ? xIndex - yIndex : (xIndex !== -1) ? -1 : (yIndex !== -1) ? 1 : 0
    })
  })

  return cloneGroups
}
