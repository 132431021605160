import { CheckoutData } from '~/composables/useCheckoutState/interfaces/CheckoutData'
import { CheckoutStep } from '~/composables/useCheckoutState/enums/CheckoutStep'

export const CHECKOUT_INITIAL_DATA: CheckoutData = {
  [CheckoutStep.contactInfo]: {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    submitted: false
  },
  [CheckoutStep.shipping]: {
    // itemsGroups: [],
    furnitureUtilization: false,
    furnitureAssembly: false,
    selectedMethod: null,
    anotherRecipient: false,
    address: {
      email: '',
      firstName: '',
      lastName: '',
      street: '',
      city: '',
      postcode: '',
      country: 'EE',
      region: {
        region_code: '',
        region_id: null
      },
      phoneNumber: ''
    },
    additional_data: {
      place_id: null
    },
    submitted: false
  },
  [CheckoutStep.payment]: {
    isCompanyInvoice: false,
    sameAsShipping: true,
    registerCode: '',
    taxVat: '',
    companyName: '',
    paymentMethod: '',
    submitted: false
  }
}
