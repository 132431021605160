import { Plugin } from '@nuxt/types'
import { onGlobalSetup, watch } from '@nuxtjs/composition-api'
import { useManagedNewsletterStore } from '~/modules/managed-newsletter/store/useManagedNewsletterStore'
import { useManagedNewsletter } from '~/modules/managed-newsletter/composables/useManagedNewsletter'

// eslint-disable-next-line func-style
const managedNewsletterPlugin: Plugin = (app) => {
  onGlobalSetup(async () => {
    const managedNewsletterStore = useManagedNewsletterStore(app.$pinia)
    const { isTargetPage, config, openModal, isOpenAllowed } = useManagedNewsletter()
    let openedModalTimeout: ReturnType<typeof setTimeout> | undefined

    if (!process.server) {
      await managedNewsletterStore.fetch()

      watch(isTargetPage, (val: boolean) => {
        if (openedModalTimeout) {
          clearTimeout(openedModalTimeout)
          openedModalTimeout = undefined
        }

        if (val && isOpenAllowed()) {
          openedModalTimeout = setTimeout(() => {
            openModal()
          }, config.value.openAfterSeconds)
        }
      }, { immediate: true, deep: true })
    }
  })
}

// eslint-disable-next-line import/no-default-export
export default managedNewsletterPlugin
