import GetProductListQuery from '~/customQueries/productList'
import { GetProductListExecute, Products, useApi } from '~/composables'
import { GetProductSearchParams } from '~/modules/catalog/product/types'

export const getProductListCommand = (): GetProductListExecute => {
  const { query } = useApi()

  const execute = async (searchParams: GetProductSearchParams): Promise<Products | null> => {
    const { data } = await query<{ products: Products }>(GetProductListQuery, searchParams)

    return data?.products ?? null
  }

  return {
    execute
  }
}
