import { AvailablePaymentMethod, Cart, Order as BaseOrder } from '~/modules/GraphQL/types'
import { PaymentMethods } from '~/modules/vsf-payments-redirect/config/PaymentMethods'

export enum AatriumPaymentGroups {
  installment = 'installment',
  bankLink = 'bankLink',
  invoice = 'invoice',
  other = 'other'
}

export interface AatriumPaymentGroupMetaItem {
  label: string
  type: string
}

export interface AatriumAvailablePaymentMethod extends AvailablePaymentMethod {
  group_code?: AatriumPaymentGroups
  logo_url?: string
  bank_id?: string
}

export interface AatriumPaymentGroup extends AatriumPaymentGroupMetaItem {
  groupCode: AatriumPaymentGroups
  methods: AatriumAvailablePaymentMethod[]
}

export interface AatriumPaymentMethods {
  installment: AatriumAvailablePaymentMethod[]
  bankLinks: AatriumAvailablePaymentMethod[]
  invoice: AatriumAvailablePaymentMethod
  other: AatriumAvailablePaymentMethod[]
}

export interface PaymentData {
  payment_url: string | undefined | null
  payment_data?: string | undefined
}
export interface RedirectPaymentData extends PaymentData {
  method: PaymentMethods
}

export interface Order extends BaseOrder {
  montonio_payment_data: PaymentData
  inbank_payment_data: PaymentData
  esto_payment_data: PaymentData
  liisi_payment_data: PaymentData
  lhv_payment_data: PaymentData
}

export interface StoredOrderInLc {
  orderId: number | string
  cart: Cart
}
