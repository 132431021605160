
import { computed, defineComponent } from '@nuxtjs/composition-api'
import HeaderButton from '~/components/Header/Buttons/HeaderButton.vue'

import { getFirstName } from '~/modules/customer/getters/userGetters'
import { useUiState } from '~/composables'
import { useUser } from '~/modules/customer/composables/useUser'

export default defineComponent({
  name: 'AccountIcon',
  components: {
    HeaderButton
  },
  setup () {
    const { isAuthenticated, user } = useUser()
    const { toggleAuthSidebar, toggleMyAccountSidebar } = useUiState()
    const initial = computed(() => getFirstName(user.value)?.[0])

    function handleClick (): void {
      if (isAuthenticated.value) {
        toggleMyAccountSidebar()
      } else {
        toggleAuthSidebar()
      }
    }

    return {
      isAuthenticated,
      initial,
      handleClick
    }
  }
})
