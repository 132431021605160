import { ProductPriceProj } from './ProductPrice.proj'
import { ProductAvailabilityInStoresProj } from './ProductAvailabilityInStores.proj'
import { ProductMediaProj } from './ProductMedia.proj'

export const ProductShortProj = `#graphql
${ProductPriceProj}
${ProductMediaProj}
${ProductAvailabilityInStoresProj}
fragment ProductShortProj on ProductInterface {
    id
    uid
    sku
    name
    stock_status
    only_x_left_in_stock
    new_to_date
    image {
      url
      position
      disabled
      label
    }
    url_key
    categories {
      uid
      name
      url_suffix
      url_path
    }
    ...ProductMediaProj
    ...ProductPriceProj
    ...ProductAvailabilityInStoresProj
  }
`
