
export function productMatch (
  productA: {sku?: string, uid?: string},
  productB: {sku?: string, uid?: string}
): boolean {
  const equalSku = productA?.sku && productB?.sku && productA?.sku === productB?.sku
  const equalUid = productA?.uid && productB?.uid && productA?.uid === productB?.uid

  return equalSku || equalUid
}
