import { VsfContext } from '~/composables/context'
import { Logger } from '~/helpers/logger'
import { Cart, CartItemInput, AddVirtualProductsToCartInput } from '~/modules/GraphQL/types'
import { CustomQuery } from '~/types/core'

/** Params object used to add items to a cart */
export declare interface AddProductsToCartInput {
  cartId: string
  cartItems: CartItemInput[]
}

export const addItemCommand = {
  execute: async (
    context: VsfContext,
    {
      product,
      quantity,
      currentCart,
      entered_options: enteredOptions,
      productConfiguration,
      customQuery
    }
  ) => {
    Logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart
    })

    const apiState = context.$magento.config.state
    const cartId = apiState.getCartId()

    if (!product) {
      return
    }
    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case 'GiftCardProduct':
      case 'SimpleProduct':
        // eslint-disable-next-line no-case-declarations
        const simpleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku
            }
          ]
        }

        if (enteredOptions) {
          simpleCartInput.cartItems[0].entered_options = enteredOptions
        }

        // eslint-disable-next-line no-case-declarations
        const simpleProduct = await context.$magento.api.addProductsToCart(simpleCartInput, customQuery as CustomQuery)

        Logger.debug('[Result]:', { data: simpleProduct.data })

        if (simpleProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(simpleProduct.data.addProductsToCart.user_errors[0].message))
        }

        // eslint-disable-next-line consistent-return
        return simpleProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart
      case 'ConfigurableProduct':
        // eslint-disable-next-line no-case-declarations
        const selectedOptions = Object.values(productConfiguration as object)

        // eslint-disable-next-line no-case-declarations
        const configurableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              selected_options: selectedOptions
            }
          ]
        }

        // eslint-disable-next-line no-case-declarations
        const configurableProduct = await context.$magento.api.addProductsToCart(configurableCartInput, customQuery as CustomQuery)
        Logger.debug('[Result]:', { data: configurableProduct.data })

        if (configurableProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(configurableProduct.data.addProductsToCart.user_errors[0].message))
        }

        // eslint-disable-next-line consistent-return
        return configurableProduct.data
          .addProductsToCart
          .cart as unknown as Cart
      case 'BundleProduct':
        // eslint-disable-next-line no-case-declarations
        const createEnteredOptions = (): any[] => {
          // eslint-disable-next-line implicit-arrow-linebreak
          return product.bundle_options.map(bundleOption => ({
            ...bundleOption,
            value: bundleOption.value.toString()
          }))
        }

        // eslint-disable-next-line no-case-declarations
        const bundleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              entered_options: createEnteredOptions()
            }
          ]
        }

        // eslint-disable-next-line no-case-declarations
        const bundleProduct = await context.$magento.api.addProductsToCart(bundleCartInput, customQuery as CustomQuery)

        Logger.debug('[Result]:', { data: bundleProduct })

        if (bundleProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(bundleProduct.data.addProductsToCart.user_errors[0].message))
        }

        // eslint-disable-next-line consistent-return
        return bundleProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart
      case 'DownloadableProduct':
        // eslint-disable-next-line no-case-declarations
        const downloadableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku
            }
          ]
        }

        // eslint-disable-next-line no-case-declarations
        const downloadableProduct = await context.$magento.api.addProductsToCart(downloadableCartInput, customQuery as CustomQuery)

        Logger.debug('[Result DownloadableProduct]:', { data: downloadableProduct })

        if (downloadableProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(downloadableProduct.data.addProductsToCart.user_errors[0].message))
        }

        // eslint-disable-next-line consistent-return
        return downloadableProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart
      case 'VirtualProduct':
        // eslint-disable-next-line no-case-declarations
        const virtualCartInput: AddVirtualProductsToCartInput = {
          cart_id: cartId,
          cart_items: [{
            data: {
              quantity,
              sku: product.sku
            }
          }]
        }
        // eslint-disable-next-line no-case-declarations
        const virtualProduct = await context.$magento.api.addVirtualProductsToCart(virtualCartInput, customQuery as CustomQuery)

        Logger.debug('[Result VirtualProduct]:', { data: virtualProduct })

        if (virtualProduct.data === null) {
          throw new Error('addVirtualProductsToCart Error')
        }

        return virtualProduct
          .data
          .addVirtualProductsToCart
          .cart as unknown as Cart
      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename as string} not supported in add to cart yet`)
    }
  }
}
