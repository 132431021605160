import { EstAuthCustomerType, EstAuthMethod } from '~/composables/useEstAuth/types';
import { Context } from '@vue-storefront/core';
import { EstAuthError } from './authError';
import { AuthSidebarMode } from "~/composables/useUiState/enums/AuthSidebarMode";

export const authMethodFactory = (
  context: Context,
  method: EstAuthMethod,
  mode: AuthSidebarMode,
  customerType: EstAuthCustomerType
) => {
  try {
    const authMethod = require(`./methods/${method}`).default;
    return authMethod(context, customerType);
  } catch (err) {
    throw new EstAuthError('authMethodFactory', 'Unhandled auth method!');
  }
};
