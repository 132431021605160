import { multiply } from 'lodash-es'
import { ManagedNewsletter } from '~/modules/managed-newsletter/types'

export function prepareConfig (payload: ManagedNewsletter): ManagedNewsletter | null {
  if (!payload) {
    return
  }

  return {
    id: payload.id,
    heading: payload.heading,
    subheading: payload.subheading ?? '',
    description: payload.description ?? '',
    headingImage: payload.headingImage ?? null,
    showOnPages: payload.showOnPages ?? '/',
    reopenAfterClosedHours: multiply(payload.reopenAfterClosedHours ?? 48, 60 * 60 * 1000),
    openAfterSeconds: multiply(payload.openAfterSeconds ?? 15, 1000)
  }
}
